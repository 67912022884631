import img1 from '~/UnsubscribeMailers/assets/city.png';

export const ImgSection = () => (
  <section>
    <img
      src={img1}
      alt='Graphic of the estate'
      className='mx-auto w-full h-auto max-w-[1140px]'
      width='1140'
      height='300'
      loading='lazy'
    />
  </section>
);
