import { useTranslationContext } from '~/contexts/TranslationContext';
import type { InitialData } from '~/UnsubscribeMailers/Api/usePostToken/types';
import { Switcher } from '~/atoms';
import { ImgSection, Header } from '~/UnsubscribeMailers/App/components';
import { SettingName } from '~/UnsubscribeMailers/contexts/SettingsContext/types';
import { PreferenceBox } from './PreferenceBox';
import { SaveButton } from './SaveButton';
import { usePreferences } from './usePreferences';
import type { View } from '../../types';

type Props = {
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  initialData: InitialData | undefined;
};

export const PreferencesView = ({ setCurrentView, initialData }: Props) => {
  const { i18n } = useTranslationContext();

  const { isSelectAllChecked, settings, handleChange, handleSelectAllChange } =
    usePreferences();

  return (
    <>
      <Header
        titles={[
          i18n.t('javascript.unsubscribe_mailers.components.header.title_1'),
        ]}
        subtitles={[
          i18n.t('javascript.unsubscribe_mailers.components.header.subtitle_1'),
          i18n.t('javascript.unsubscribe_mailers.components.header.subtitle_2'),
        ]}
        email={initialData?.email ? initialData.email : ''}
      />
      <section className='wrapper pt-4 pb-8 space-y-6'>
        <div className='flex justify-end items-center px-4 space-x-2'>
          <span className='text-base'>
            {i18n.t('javascript.unsubscribe_mailers.preferences_view.select')}:
          </span>
          <Switcher
            domId='preference-switcher-0'
            checkboxName='selectAll'
            isChecked={isSelectAllChecked}
            onChange={handleSelectAllChange}
            checkedText={i18n.t(
              'javascript.unsubscribe_mailers.components.switcher.all'
            )}
            uncheckedText={i18n.t(
              'javascript.unsubscribe_mailers.components.switcher.none'
            )}
          />
        </div>
        <PreferenceBox
          switcherId='preference-switcher-1'
          switcherName={SettingName.searchAgent}
          isSwitcherChecked={settings.searchAgent}
          onSwitcherChange={handleChange}
          title={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.search_agent_title'
          )}
          description={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.search_agent_description'
          )}
        />
        <PreferenceBox
          switcherId='preference-switcher-2'
          switcherName={SettingName.inactivity}
          isSwitcherChecked={settings.inactivity}
          onSwitcherChange={handleChange}
          title={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.inactivity_title'
          )}
          description={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.inactivity_description'
          )}
        />
        <PreferenceBox
          switcherId='preference-switcher-3'
          switcherName={SettingName.commercial}
          isSwitcherChecked={settings.commercial}
          onSwitcherChange={handleChange}
          title={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.commercial_title'
          )}
          description={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.commercial_description'
          )}
        />
        <PreferenceBox
          switcherId='preference-switcher-4'
          switcherName={SettingName.onboarding}
          isSwitcherChecked={settings.onboarding}
          onSwitcherChange={handleChange}
          title={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.onboarding_title'
          )}
          description={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.onboarding_description'
          )}
        />
        <PreferenceBox
          switcherId='preference-switcher-5'
          switcherName={SettingName.other}
          isSwitcherChecked={settings.other}
          onSwitcherChange={handleChange}
          title={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.other_title'
          )}
          description={i18n.t(
            'javascript.unsubscribe_mailers.preferences_view.other_description'
          )}
        />
      </section>
      <SaveButton setCurrentView={setCurrentView} settings={settings} />
      <ImgSection />
    </>
  );
};
