import { useMutation } from 'react-query';
import axios from 'axios';

type Args = {
  body: {
    answer: string;
    token: string;
  };
};

const postHowOftenSurveyAnswer = ({ body }: Args) =>
  axios.post('/api/v1/unsubscribe_mailers/how_often', body);

export const usePostHowOftenSurveyAnswer = () =>
  useMutation(postHowOftenSurveyAnswer);
