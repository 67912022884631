import { Controller } from 'stimulus';
import { percentageToRunEzoic, doesHostAllowsSplitTesting } from './utils';

export default class extends Controller {
  connect = () => {
    const loadedPlaceholder = this.element;
    const loadedPlaceholderId = this.getId();
    const placeholders = window.ezstandalone.placeholders || [];

    const isAlreadyDefined = placeholders.some(
      (placeholderId) => placeholderId === loadedPlaceholderId
    );

    if (loadedPlaceholderId && !isAlreadyDefined) {
      window.ezstandalone.cmd.push(() => {
        console.log("Ezoic cmd.push calling");
        if (this.shouldUserSeeAds()) {
          window.ezstandalone.displayMore(loadedPlaceholderId);
          console.log("Ezoic displayMore", loadedPlaceholderId);
        } else {
          loadedPlaceholder.parentElement?.classList.add('!hidden');
        }
      });
    }
  };

  getId() {
    const loadedPlaceholder = this.element;

    const idSegments = loadedPlaceholder.id.split('-');
    const id = Number(idSegments[idSegments.length - 1]);
    if (!!loadedPlaceholder.offsetParent) {
      return id;
    }
    return null;
  }

  shouldUserSeeAds = () =>
    (doesHostAllowsSplitTesting() &&
      window.ezstandalone.isEzoicUser(percentageToRunEzoic) === true) ||
    !doesHostAllowsSplitTesting();
}
