import { useCellWidthContext } from '~/CampaignTable/contexts';
import type { SortCategory, DataItem } from '../../types';
import { TableCell } from '~/CampaignTable/components';

type Props = {
  item: DataItem;
  rank: number;
  sortCategory: SortCategory;
  isLightTheme: boolean;
  isMobile: boolean;
};

export const TableRow = ({ item, rank, sortCategory, isLightTheme, isMobile }: Props) => {
  const { cellWidth } = useCellWidthContext();

  return (
    <div className={`flex py-4${
        isLightTheme ? ' bg-[#FEFAF5]' : ''
    }`}>
      <TableCell width={cellWidth}>{rank}</TableCell>
      <TableCell width={isMobile ? cellWidth : 2 * cellWidth}>
        {item.url ? (
            <a href={item.url}>{item.city}</a>
        ) : (
            <>
            {item.city}
            </>)}
      </TableCell>
      {isMobile ? (
        <TableCell width={cellWidth}>
          {item.indexesValues[sortCategory] || '-'}
        </TableCell>
      ) : (
        <>
          {Object.values(item.indexesValues).map((indexValue, arrIndex) => (
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={arrIndex}
              width={cellWidth}
            >
              {indexValue || '-'}
            </TableCell>
          ))}
        </>
      )}
    </div>
  );
};
