import { useState, useRef, useEffect } from 'react';

import { useTranslationContext } from '~/contexts/TranslationContext';
import styles from './Switcher.module.scss';

type Props = {
  domId: string;
  checkboxName: string;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkedText?: string;
  uncheckedText?: string;
  wrapperClassName?: string;
};

const ADDITIONAL_SPACE_INSIDE_SWITCHER_IN_PX = 45;

export const Switcher = ({
  domId,
  checkboxName,
  isChecked,
  onChange,
  checkedText,
  uncheckedText,
  wrapperClassName = '',
}: Props) => {
  const [width, setWidth] = useState(0);

  const { i18n } = useTranslationContext();

  const checkedTextRef = useRef<HTMLSpanElement>(null);
  const uncheckedTextRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (checkedTextRef.current && uncheckedTextRef.current) {
      const checkedTextWidth = checkedTextRef.current.clientWidth;
      const uncheckedTextWidth = uncheckedTextRef.current.clientWidth;

      const longerWordWidth =
        checkedTextWidth >= uncheckedTextWidth
          ? checkedTextWidth
          : uncheckedTextWidth;

      setWidth(longerWordWidth + ADDITIONAL_SPACE_INSIDE_SWITCHER_IN_PX);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTextRef.current, uncheckedTextRef.current]);

  return (
    <div className={`flex relative ${wrapperClassName}`}>
      <input
        type='checkbox'
        id={domId}
        name={checkboxName}
        checked={isChecked}
        onChange={onChange}
        className={styles.checkbox}
      />
      <label
        htmlFor={domId}
        className={styles.label}
        style={{ width: `${width}px` }}
      >
        <span
          className={`${
            isChecked ? 'opacity-100' : 'opacity-0'
          } absolute top-1/2 left-[10px] -translate-y-1/2 text-white font-bold`}
          ref={checkedTextRef}
        >
          {checkedText ||
            i18n.t(
              'javascript.unsubscribe_mailers.components.switcher.text_yes'
            )}
        </span>
        <span
          className={`${
            isChecked ? 'opacity-0' : 'opacity-100'
          } absolute top-1/2 right-[10px] -translate-y-1/2 text-[#B2AAAA] font-bold`}
          ref={uncheckedTextRef}
        >
          {uncheckedText ||
            i18n.t(
              'javascript.unsubscribe_mailers.components.switcher.text_no'
            )}
        </span>
      </label>
    </div>
  );
};
