import { I18n } from 'i18n-js/typings';
import Cookies from 'js-cookie';

export const determineError = (error: unknown, i18n: I18n) => {
  const failedPaymentAttempts =
    Number(Cookies.get('failed_payment_attempts_counter')) || 0;

  if (!Number.isNaN(failedPaymentAttempts) && failedPaymentAttempts > 1) {
    return {
      msg: `<p class="text-sm mb-4">${i18n.t(
        'javascript.rebilly.subscription.error_second_payment_failure_1'
      )}</p>
      <p class="text-sm italic">${i18n.t(
        'javascript.rebilly.subscription.error_second_payment_failure_2',
        {
          email:
            document.querySelector('html')?.dataset.mailto ||
            'info@rentola.com',
        }
      )}</p>`,
      isHtmlUsed: true,
      alertClass: 'alert-danger'
    };
  }
  switch (error) {
    case 'Refused':
    case 'Authentication required':
    case 'abandoned':
    case 'canceled':
    case 'Your card was declined.': // Stripe case
    case 'declined':
      return {
        msg: i18n.t('javascript.rebilly.subscription.error_processing_generic'),
        isHtmlUsed: false,
        alertClass: 'alert-info'
      };
    case 'CVC Declined':
      return {
        msg: i18n.t('javascript.rebilly.subscription.error_cvc_declined'),
        isHtmlUsed: false,
        alertClass: 'alert-danger'
      };
    case 'Invalid':
      return {
        msg: i18n.t('javascript.rebilly.subscription.error_invalid_card'),
        isHtmlUsed: false,
        alertClass: 'alert-danger'
      };
    case 'Your card has expired.': // Stripe case
      return {
        msg: i18n.t('javascript.rebilly.subscription.error_expired'),
        isHtmlUsed: false,
        alertClass: 'alert-danger'
      }
    default:
      return {
        msg: i18n.t('javascript.rebilly.subscription.error_processing_generic'),
        isHtmlUsed: false,
        alertClass: 'alert-info'
      };
  }
};
