import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useEffect } from 'react';

import { TranslationContextProvider } from '~/contexts/TranslationContext';
import { SubscriptionCheckout } from './SubscriptionCheckout';
import type { RebillyConfig } from './types';

const queryClient = new QueryClient();

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  useEffect(() => {
    if (
      error.message ===
      `Cannot read properties of undefined (reading 'destroy')`
    ) {
      resetErrorBoundary();
    }
  }, [error.message]);

  return null;
}

const handleError = (error: Error) => {
  console.error('Error caught by ErrorBoundry:', error);
};

export const App = (rebillyConfig: RebillyConfig) => (
  <QueryClientProvider client={queryClient}>
    <TranslationContextProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <SubscriptionCheckout rebillyConfig={rebillyConfig} />
      </ErrorBoundary>
    </TranslationContextProvider>
  </QueryClientProvider>
);
