import { useEffect } from 'react';

import { useSettingsContext } from '~/UnsubscribeMailers/contexts';

export const usePreferences = () => {
  const {
    settings,
    setSettings,
    handleChange,
    isSelectAllChecked,
    setIsSelectAllChecked,
    handleSelectAllChange,
  } = useSettingsContext();

  useEffect(() => {
    if (isSelectAllChecked) {
      setSettings({
        searchAgent: true,
        inactivity: true,
        commercial: true,
        onboarding: true,
        other: true,
      });
    } else {
      setSettings({
        searchAgent: false,
        inactivity: false,
        commercial: false,
        onboarding: false,
        other: false,
      });
    }
  }, [isSelectAllChecked]);

  useEffect(() => {
    if (Object.values(settings).every((setting) => setting)) {
      setIsSelectAllChecked(true);
    } else if (Object.values(settings).every((setting) => !setting)) {
      setIsSelectAllChecked(false);
    }
  }, [settings]);

  return {
    isSelectAllChecked,
    settings,
    handleChange,
    handleSelectAllChange,
  };
};
