import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['hamburger', 'mobileMenu', 'nav'];

  connect = () => {
    const mobileMenu = this.mobileMenuTarget;
    const navLinks = mobileMenu.querySelectorAll('a');
    navLinks.forEach((link) => {
      link.addEventListener('click', () => {
        this.toggle();
      });
    });
  };

  toggle() {
    this.hamburgerTarget.classList.toggle('active');
    this.mobileMenuTarget.classList.toggle('active');
    this.navTarget.classList.toggle('active');
    this.element.classList.toggle('active');
  }
}
