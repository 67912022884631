import { useTranslationContext } from '~/contexts/TranslationContext';
import { useCellWidthContext } from '~/CampaignTable/contexts';
import { getIconName } from '~/CampaignTable/utils/IconsMapping';
import type { SortOrder, SortCategory } from '../../types';
import { Button } from '~/atoms';
import { TableHeader, DynamicIcon } from '~/CampaignTable/components';

type Props = {
  sortOrder: SortOrder;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
  sortCategory: SortCategory;
  currentCountry: string;
  i18nPrefix: string;
};

export const MobileTableHeader = ({
  sortOrder,
  setSortOrder,
  sortCategory,
  i18nPrefix,
}: Props) => {
  const { cellWidth } = useCellWidthContext();

  const { i18n } = useTranslationContext();

  const handleClick = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <TableHeader width={cellWidth}>
      <Button
        className='flex items-center px-2 py-1 space-x-3'
        onClick={handleClick}
      >
        {sortCategory === 'index' ? (
          <span>
            {i18n.t(`${i18nPrefix}.index`)}
          </span>
        ) : (
          <DynamicIcon iconName={getIconName(sortCategory)} />
        )}
        <span
          className={`caret transition-transform${
            sortOrder === 'asc' ? ' rotate-180' : ''
          }`}
        />
      </Button>
    </TableHeader>
  );
};
