import { useState, useCallback, createContext, useContext } from 'react';
import { Settings, SettingName } from './types';

const useSettings = () => {
  const [settings, setSettings] = useState<Settings>({
    [SettingName.searchAgent]: true,
    [SettingName.inactivity]: true,
    [SettingName.commercial]: true,
    [SettingName.onboarding]: true,
    [SettingName.other]: true,
  });
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const key = e.currentTarget.name as SettingName;
      setSettings({
        ...settings,
        [key]: e.currentTarget.checked,
      });
    },
    [settings]
  );

  const handleSelectAllChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSelectAllChecked(e.currentTarget.checked);
    },
    []
  );

  return {
    settings,
    setSettings,
    isSelectAllChecked,
    setIsSelectAllChecked,
    handleChange,
    handleSelectAllChange,
  };
};

type UseSettings = ReturnType<typeof useSettings>;

const SettingsContext = createContext<UseSettings | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const SettingsContextProvider = ({ children }: Props) => (
  <SettingsContext.Provider value={useSettings()}>
    {children}
  </SettingsContext.Provider>
);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(
      'useSettingsContext must be within SettingsContextProvider'
    );
  }
  return context;
};
