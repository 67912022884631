import type { SortCategory } from '../../types';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { Button } from '~/atoms';
import { DynamicIcon } from '~/CampaignTable/components';
import { getIconName } from '~/CampaignTable/utils/IconsMapping';

type Props = {
  sortCategoryElement: SortCategory;
  isActive: boolean;
  setSortCategory: React.Dispatch<React.SetStateAction<SortCategory>>;
  currentCountry: string;
  isLightTheme: boolean;
  i18nPrefix: string;
};

export const SingleFilter = ({
  sortCategoryElement,
  isActive,
  setSortCategory,
  currentCountry,
  isLightTheme,
  i18nPrefix,
}: Props) => {
  const { i18n } = useTranslationContext();

  const handleClick = () => {
    setSortCategory(sortCategoryElement);
  };

  return (
    <>
      {sortCategoryElement === 'index' ? (
        <Button className='text-dark-1 mx-4' onClick={handleClick}>
          {i18n.t(`${i18nPrefix}.index`)}
        </Button>
      ) : (
        <Button
          className={`shrink-0 mx-4 p-2${
            isActive && !isLightTheme ? ' bg-grey-10 rounded' : ''}
            ${isLightTheme && isActive ? ' bg-white' : ''}`}
          onClick={handleClick}
        >
          <DynamicIcon iconName={getIconName(sortCategoryElement)} />
        </Button>
      )}
    </>
  );
};
