import { Switcher } from '~/atoms';
import img1 from '~/UnsubscribeMailers/assets/mail.png';

type Props = {
  title: string;
  description: string;
  switcherId: string;
  switcherName: string;
  isSwitcherChecked: boolean;
  onSwitcherChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const PreferenceBox = ({
  title,
  description,
  switcherId,
  switcherName,
  isSwitcherChecked,
  onSwitcherChange,
}: Props) => (
  <div className='flex items-center border border-red-1 rounded p-4'>
    <img src={img1} alt='Mail icon' width='50' height='50' />
    <div className='px-4'>
      <h3 className='text-black text-lg font-bold m-0 lg:text-xl'>{title}</h3>
      <span className='text-black lg:text-base'>{description}</span>
    </div>
    <Switcher
      domId={switcherId}
      checkboxName={switcherName}
      isChecked={isSwitcherChecked}
      onChange={onSwitcherChange}
      wrapperClassName='ml-auto'
    />
  </div>
);
