/* eslint-disable react/no-array-index-key */

import { useTranslationContext } from '~/contexts/TranslationContext';

type Props = {
  titles: string[];
  subtitles?: string[];
  email?: string;
};

export const Header = ({ titles, subtitles, email }: Props) => {
  const { i18n } = useTranslationContext();

  return (
    <header className='wrapper border-b border-grey-6 pb-6 space-y-4'>
      <div>
        {titles.map((title, index) => (
          <h1
            key={index}
            className='text-red-1 text-2xl font-bold m-0 lg:text-3xl'
          >
            {title}
          </h1>
        ))}
      </div>
      {subtitles && subtitles.length > 0 && (
        <div>
          {subtitles.map((subtitle, index) => (
            <h2 key={index} className='text-grey-2 text-lg m-0 lg:text-xl'>
              {subtitle}
            </h2>
          ))}
        </div>
      )}
      {email && (
        <h2 className='text-red-1 text-xl m-0 lg:text-2xl'>
          {i18n.t(
            'javascript.unsubscribe_mailers.components.header.email_address'
          )}
          : <strong>{email}</strong>
        </h2>
      )}
    </header>
  );
};
