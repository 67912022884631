const ANIMATION_DURATION = 400;

// modal state before 3ds flow
export const updateRailsModalBefore3DS = (
  modalHeight: number,
  modalWidth: number
) => {
  const modal = document.querySelector('.modal-content');

  if (modal && modal instanceof HTMLElement) {
    const modalChildren = [...modal.children];
    modalChildren.forEach((element) => {
      if (
        element instanceof HTMLElement &&
        element.tagName.toLowerCase() !== 'iframe'
      ) {
        // eslint-disable-next-line no-param-reassign
        element.style.display = 'none';
      }
    });

    modal.style.overflow = 'hidden';
    modal.style.margin = '0 auto';
    modal.style.border = 'none';
    modal.style.boxShadow = 'none';

    modal.animate(
      [
        { height: `${modalHeight}px`, maxWidth: `${modalWidth}px` },
        { height: '600px', maxWidth: '400px' },
      ],
      {
        duration: ANIMATION_DURATION,
        fill: 'forwards',
      }
    );
  }
};

// modal state after 3ds flow
export const updateRailsModalAfter3DS = (
  modalHeight: number,
  modalWidth: number
) => {
  const modal = document.querySelector('.modal-content');

  if (modal && modal instanceof HTMLElement) {
    const animation = modal.animate(
      [
        { height: '600px', maxWidth: '400px' },
        { height: `${modalHeight}px`, maxWidth: `${modalWidth}px` },
      ],
      {
        duration: ANIMATION_DURATION,
        fill: 'forwards',
      }
    );

    animation.onfinish = () => {
      const modalChildren = [...modal.children];
      modalChildren.forEach((element) => {
        if (
          element instanceof HTMLElement &&
          element.tagName.toLowerCase() !== 'iframe'
        ) {
          // eslint-disable-next-line no-param-reassign
          element.removeAttribute('style');
        }
      });
      modal.removeAttribute('style');
      modal.animate([{ height: 'auto' }], {
        duration: 0,
        delay: 0,
        fill: 'forwards',
      });
    };
  }
};

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
export const modalFlowAfterSuccesfullPayment = (stringModalMarkup: string) => {
  $('.modal.in').modal('hide');
  $('.modal, .modal-backdrop').remove();
  $('body').append(stringModalMarkup);
  return $('#user_contact_details_modal').modal('show');
};
