import { createContext, ReactNode, useMemo, useContext } from 'react';
import { I18n } from 'i18n-js';

import translations from '../../i18n/translations.json';

const useTranslations = () => {
  const i18n = useMemo(() => new I18n(translations), []);

  const htmlElement = document.querySelector('html');
  const currentLocale = htmlElement?.lang || 'en';

  i18n.locale = currentLocale;
  i18n.enableFallback = true;

  return { i18n };
};

type UseTranslations = ReturnType<typeof useTranslations>;

const TranslationContext = createContext<UseTranslations | undefined>(
  undefined
);

type Props = {
  children: ReactNode;
};

export const TranslationContextProvider = ({ children }: Props) => (
  <TranslationContext.Provider value={useTranslations()}>
    {children}
  </TranslationContext.Provider>
);

export const useTranslationContext = () => {
  const context = useContext(TranslationContext);
  if (context === undefined) {
    throw new Error(
      'useTranslationContext must be within TranslationContextProvider'
    );
  }
  return context;
};
