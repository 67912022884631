export type Settings = {
  searchAgent: boolean;
  inactivity: boolean;
  commercial: boolean;
  onboarding: boolean;
  other: boolean;
};

export enum SettingName {
  searchAgent = 'searchAgent',
  inactivity = 'inactivity',
  commercial = 'commercial',
  onboarding = 'onboarding',
  other = 'other',
}
