import { useMemo } from 'react';
import type { SortOrder, Data, SortCategory } from './types';

type Args = {
  data: Data;
  sortCategory: SortCategory;
  sortOrder: SortOrder;
};

export const useApp = ({ data, sortCategory, sortOrder }: Args) => {
  const sortedData = useMemo(() => {
    const tempSortedData = data.sort((itemA, itemB) => {
      const valueA = itemA.indexesValues[sortCategory];
      const valueB = itemB.indexesValues[sortCategory];

      if (valueA === null) return -1;
      if (valueB === null) return 1;
      return Number(valueA) - Number(valueB);
    });

    if (sortOrder === 'desc') {
      return tempSortedData.reverse();
    }
    return tempSortedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortCategory, sortOrder]);

  const indexes = useMemo(() => sortedData[0].indexesValues, [sortedData]);

  const sortCategoriesList = useMemo(
    () => Object.keys(indexes) as (keyof typeof indexes)[],
    [indexes]
  );

  return { sortedData, sortCategoriesList };
};
