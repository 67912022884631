import type { SearchResults } from './types';

export const isDataEmpty = (data: SearchResults) => {
  if (
    data.addresses.suggestions.length === 0 &&
    data.cities.suggestions.length === 0 &&
    data.countries.suggestions.length === 0 &&
    data.districts.suggestions.length === 0 &&
    data.neighborhoods.suggestions.length === 0 &&
    data.postal_codes.suggestions.length === 0 &&
    data.regions.suggestions.length === 0
  ) {
    return true;
  }

  return false;
};
