import React, { useState } from 'react';

import { useGetSearchResults } from '~/SearchBox/Api/useGetSearchResults';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { Input, Spinner } from '~/atoms';
import { useDebounce } from '~/hooks';
import { Dropdown } from './Dropdown';
import icon from '~/SearchBox/assets/images/search-icon.svg';
import styles from './SearchBox.module.scss';

type Props = {
  searchBaseUrl: string;
};

export const SearchBox = ({ searchBaseUrl }: Props) => {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 400);

  const searchResultsQuery = useGetSearchResults(
    debouncedSearchText.toLowerCase()
  );

  const { i18n } = useTranslationContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div className='max-w-[650px] mx-auto'>
      <div
        className={`${styles.boxHeight} flex items-center relative bg-white rounded py-2 pr-2 pl-4 space-x-4`}
      >
        <div className='flex justify-center items-center w-[30px] h-[30px]'>
          {searchResultsQuery.isFetching ? (
            <Spinner isBig color='grey' />
          ) : (
            <img src={icon} alt='Search icon' className='w-full h-full' />
          )}
        </div>
        <Input
          className='flex-1 h-full text-base bg-transparent outline-none placeholder:text-sm'
          onChange={handleChange}
          placeholder={i18n.t('javascript.search_box.input_placeholder')}
        />
        {(searchResultsQuery.isError || searchResultsQuery.isSuccess) && (
          <Dropdown
            isRequestError={searchResultsQuery.isError}
            data={searchResultsQuery.data}
            searchText={searchText}
            searchBaseUrl={searchBaseUrl}
          />
        )}
      </div>
    </div>
  );
};
