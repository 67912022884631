/* eslint-disable react/jsx-props-no-spreading */

import styles from './Input.module.scss';

type Props = React.ComponentProps<'input'> & {
  className?: string;
};

export const Input = ({ className = '', ...rest }: Props) => (
  <input type='text' {...rest} className={`${className} ${styles.input}`} />
);
