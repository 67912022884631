import { useRef, useState } from 'react';
import { withFramePay, FramePayComponentProps } from '@rebilly/framepay-react';

import type { OrderData } from '~/SubscriptionCheckout/Api/types';
import { FramePayCardElements } from './FramePayCardElements';
import {
  Input,
  BackButton,
  SubmitButton,
  Form,
  Iframe,
  SuccessfulPaymentAlert,
  ErrorAlert,
} from '~/SubscriptionCheckout/components';
import type {
  CurrentView,
  AttributionData,
  TrackingData,
} from '~/SubscriptionCheckout/App/types';
import { useCard } from './useCard';
import { useTranslationContext } from '~/contexts/TranslationContext';
import styles from './Card.module.scss';
import { ModalDimensions } from '~/SubscriptionCheckout/hooks/useModalDimensions/types';

type Props = FramePayComponentProps & {
  setCurrentView: React.Dispatch<React.SetStateAction<CurrentView>>;
  paymentData: OrderData['plan'];
  attribution: AttributionData;
  trackingData: TrackingData | null;
  modalDimensions: ModalDimensions;
};

const Card = ({
  Rebilly,
  setCurrentView,
  paymentData: { trialDays, trialPriceFormatted },
  attribution,
  trackingData,
  modalDimensions,
}: Props) => {
  const [isCardReady, setIsCardReady] = useState({
    cardNumber: false,
    expiry: false,
    cvv: false,
  });

  const { i18n } = useTranslationContext();

  const formRef = useRef<HTMLFormElement>(null);

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setCardDetailsComplete,
    isLoading,
    errorMsg,
    handleSubmit,
    isIframeVisible,
    urlToOffsiteFlow,
    isPaymentSucceeded,
  } = useCard({
    formRef,
    Rebilly,
    attribution,
    trackingData,
    modalDimensions,
  });

  const cardNotReady =
    !isCardReady.cardNumber && !isCardReady.expiry && !isCardReady.cvv;
  return (
    <div className='margin-top-lg'>
      {isPaymentSucceeded ? (
        <SuccessfulPaymentAlert />
      ) : (
        <>
          <BackButton
            onClick={() => setCurrentView('buttons')}
            disabled={isLoading || cardNotReady}
          />
          {errorMsg.msg && <ErrorAlert errorMsg={errorMsg} />}
          <Form handleSubmit={handleSubmit} formRef={formRef}>
            <div className={`${styles.inputWrapper} ${styles.firstName}`}>
              <Input
                value={firstName}
                placeholder={i18n.t(
                  'javascript.rebilly.subscription.first_name'
                )}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className={`${styles.inputWrapper} ${styles.lastName}`}>
              <Input
                value={lastName}
                placeholder={i18n.t(
                  'javascript.rebilly.subscription.last_name'
                )}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <FramePayCardElements
              isCardReady={isCardReady}
              setIsCardReady={setIsCardReady}
              setCardDetailsComplete={setCardDetailsComplete}
            />
            <SubmitButton isLoading={isLoading} className={styles.submitButton}>
              <strong>
                {i18n.t('javascript.rebilly.subscription.start_trial', {
                  trial_days: trialDays,
                  trial_price: trialPriceFormatted,
                })}
              </strong>
            </SubmitButton>
          </Form>
        </>
      )}
      {isIframeVisible && urlToOffsiteFlow && (
        <Iframe sourceUrl={urlToOffsiteFlow} title='Offsite payment flow' />
      )}
    </div>
  );
};

export const FramePayCard = withFramePay(Card);
