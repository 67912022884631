import { useState, useEffect } from 'react';

import type { IconName } from '~/CampaignTable/App/types';

type ImgModule = {
  default: string;
};

export const useDynamicImg = (imgName: IconName, fileExtension: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imagePath, setImagePath] = useState('');

  useEffect(() => {
    let ignore = false;

    const importImg = async () => {
      try {
        const response = (await import(
          `../../../images/seo_campaigns/icons/${imgName}.${fileExtension}`
        )) as ImgModule;
        if (!ignore) {
          setIsLoading(false);
          setImagePath(response.default);
        }
      } catch (err) {
        setIsLoading(false);
        setImagePath('');
        console.error(err);
      }
    };
    importImg();

    return () => {
      ignore = true;
    };
  }, [imgName, fileExtension]);

  return { imagePath, isLoading };
};
