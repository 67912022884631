import { QueryClient, QueryClientProvider } from 'react-query';

import { TranslationContextProvider } from '~/contexts/TranslationContext';
import { SearchBox } from './SearchBox';
import './index.module.scss';

type Props = {
  defaultUrl: string;
};

const queryClient = new QueryClient();

export const App = ({ defaultUrl }: Props) => (
  <QueryClientProvider client={queryClient}>
    <TranslationContextProvider>
      <SearchBox searchBaseUrl={defaultUrl} />
    </TranslationContextProvider>
  </QueryClientProvider>
);
