import { useQuery } from 'react-query';
import axios from 'axios';

import { QueryKeys } from '../queryKeys';
import type { SearchResults } from './types';
import { SearchResultsSchema } from './types';

const getSearchResults = async (searchText: string): Promise<SearchResults> => {
  const { data } = await axios.get(`/api/v1/geo/search?q=${searchText}`);

  const validationResult = SearchResultsSchema.safeParse(data);

  if (!validationResult.success) {
    console.error(validationResult.error);
    throw Error('Data validation error!');
  }

  return validationResult.data;
};

export const useGetSearchResults = (searchText: string) =>
  useQuery(
    [QueryKeys.SearchResults, { searchText }],
    () => getSearchResults(searchText),
    {
      enabled: !!searchText,
      keepPreviousData: !!searchText,
      staleTime: 60000,
    }
  );
