import { useMutation } from 'react-query';
import axios from 'axios';

type Args = {
  body: {
    token: string;
    search_agent: boolean;
    commercial: boolean;
    onboarding: boolean;
    inactivity: boolean;
    other: boolean;
  };
};

const updateSettings = ({ body }: Args) =>
  axios.put('/api/v1/unsubscribe_mailers/settings', body);

export const useUpdateSettings = () => useMutation(updateSettings);
