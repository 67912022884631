import { useState, useCallback } from 'react';

import { useTranslationContext } from '~/contexts/TranslationContext';
import { usePostWhySurveyAnswer } from '~/UnsubscribeMailers/Api';
import { Radio, Spinner } from '~/atoms';
import { useGetTokenFromUrl } from '~/UnsubscribeMailers/hooks';
import {
  ImgSection,
  Header,
  LocalButton,
} from '~/UnsubscribeMailers/App/components';
import type { Survey } from '../types';

type Props = {
  setSurvey: React.Dispatch<React.SetStateAction<Survey>>;
};

export const WhySurvey = ({ setSurvey }: Props) => {
  const [answer, setAnswer] = useState(
    "I'm no longer interested in these emails"
  );

  const { i18n } = useTranslationContext();

  const postWhySurveyAnswer = usePostWhySurveyAnswer();
  const token = useGetTokenFromUrl();

  const handleAnswerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAnswer(e.target.value);
    },
    []
  );

  const handleClick = () => {
    postWhySurveyAnswer.mutate(
      { body: { answer, token } },
      {
        onSuccess: () => {
          setSurvey('howOften');
        },
        onError: (error) => {
          console.error(error);
          if (error.response?.data?.error?.surveyQuestion) {
            alert(error.response?.data?.error?.surveyQuestion[0]);
          } else {
            alert(i18n.t('javascript.unsubscribe_mailers.api_error'));
          }
        },
      }
    );
  };

  return (
    <>
      <Header
        titles={[
          i18n.t('javascript.unsubscribe_mailers.components.header.title_2'),
          i18n.t('javascript.unsubscribe_mailers.components.header.title_3'),
        ]}
        subtitles={[
          i18n.t('javascript.unsubscribe_mailers.components.header.subtitle_3'),
        ]}
      />
      <section className='wrapper pt-6 pb-8 space-y-10'>
        <h1 className='text-red-1 text-xl text-center font-bold m-0 lg:text-2xl lg:text-left'>
          {i18n.t('javascript.unsubscribe_mailers.why_survey.title')}
        </h1>
        <div className='flex flex-col space-y-6'>
          <Radio
            radioId='why-survey-radio-1'
            value="I'm no longer interested in these emails"
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t('javascript.unsubscribe_mailers.why_survey.answer_1')}
          />
          <Radio
            radioId='why-survey-radio-2'
            value='I never signed up for this mailing list'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t('javascript.unsubscribe_mailers.why_survey.answer_2')}
          />
          <Radio
            radioId='why-survey-radio-3'
            value='I don’t think the content is relevant'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t('javascript.unsubscribe_mailers.why_survey.answer_3')}
          />
          <Radio
            radioId='why-survey-radio-4'
            value='I get too many emails'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t('javascript.unsubscribe_mailers.why_survey.answer_4')}
          />
          <Radio
            radioId='why-survey-radio-5'
            value='Other'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t('javascript.unsubscribe_mailers.why_survey.answer_5')}
          />
        </div>
        <LocalButton
          onClick={handleClick}
          className='text-white bg-red-1 hover:bg-red-1-darken min-h-[48px] min-w-[171px]'
          isLoading={postWhySurveyAnswer.isLoading}
        >
          {postWhySurveyAnswer.isLoading ? (
            <Spinner color='white' wrapperClassName='justify-center' isBig />
          ) : (
            i18n.t('javascript.unsubscribe_mailers.why_survey.button')
          )}
        </LocalButton>
      </section>
      <ImgSection />
    </>
  );
};
