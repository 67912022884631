import type { SortOrder, SortCategory } from '~/CampaignTable/App/types';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { Button } from '~/atoms';
import { DynamicIcon } from '~/CampaignTable/components';
import { getIconName } from '~/CampaignTable/utils/IconsMapping';
import { useTooltip } from './useTooltip';

type Props = {
  sortCategoryElement: SortCategory;
  sortOrder: SortOrder;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
  isActive: boolean;
  setSortCategory: React.Dispatch<React.SetStateAction<SortCategory>>;
  isLightTheme: boolean;
  currentCountry: string;
  i18nPrefix: string;
};

export const SingleHeader = ({
  sortCategoryElement,
  sortOrder,
  setSortOrder,
  isActive,
  setSortCategory,
  isLightTheme,
  currentCountry,
  i18nPrefix,
}: Props) => {
  const { i18n } = useTranslationContext();

  const {
    isVisible: isTooltipVisible,
    position: tooltipPosition,
    handleMouseOver,
    handleMouseOut,
    hoveredSortCategory,
  } = useTooltip();

  const handleClick = () => {
    setSortCategory(sortCategoryElement);
    if (isActive) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('desc');
    }
  };

  const getChevronClassName = () => {
    let className = 'caret transition-transform';
    if (sortOrder === 'asc' && isActive) {
      className += ' rotate-180';
    }
    return className;
  };

  return (
    <Button
      className={`flex items-center relative px-2 py-1 space-x-1${
        isActive && !isLightTheme ? ' bg-grey-10 rounded' : ''
      } ${isLightTheme && isActive ? 'bg-white' : ''}`}
      onClick={handleClick}
      onMouseOver={(e) => handleMouseOver(e, sortCategoryElement)}
      onMouseOut={handleMouseOut}
    >
      {sortCategoryElement === 'index' ? (
        <span>{i18n.t(`${i18nPrefix}.index`)}</span>
      ) : (
        <>
          <DynamicIcon iconName={getIconName(sortCategoryElement)} />
          {isTooltipVisible && hoveredSortCategory === sortCategoryElement && (
            <div
              className='absolute text-sm bg-dark-1 text-white rounded px-3 py-1'
              style={{
                right: tooltipPosition.right,
                top: tooltipPosition.top,
              }}
            >
              {i18n.t(
                `${i18nPrefix}.categories.${sortCategoryElement}`
              )}
            </div>
          )}
        </>
      )}
      <span className={getChevronClassName()} />
    </Button>
  );
};
