import { useEffect } from 'react';

import { modalFlowAfterSuccesfullPayment } from '~/SubscriptionCheckout/utils/railsModalControl';

export const useFlowAfterSuccessfulPayment = (
  isPaymentSucceeded: boolean,
  userDetailsModalMarkup: string
) => {
  useEffect(() => {
    let time: ReturnType<typeof setTimeout>;
    if (isPaymentSucceeded && userDetailsModalMarkup) {
      time = setTimeout(() => {
        modalFlowAfterSuccesfullPayment(userDetailsModalMarkup);
      }, 2000);
    }

    return () => clearTimeout(time);
  }, [isPaymentSucceeded, userDetailsModalMarkup]);
};
