import { Controller } from 'stimulus';

export default class extends Controller {
  connect = () => { };

  validateFirstName = (e) => {
    this.validateInputByRegexp(e, new XRegExp('^[\\p{L}\\p{M}\' ]+$'))
  }

  validateMaxRent = (e) => {
    this.validateInputByRegexp(e, /[0-9\/]+/)
  }

  validateInputByRegexp = (e, regexp) => {
    if (!regexp.test(e.key)) {
      e.preventDefault();
    }
  }
}