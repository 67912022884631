import { useState, useMemo, useEffect } from 'react';

import { usePostToken } from '~/UnsubscribeMailers/Api';
import { useGetTokenFromUrl } from '~/UnsubscribeMailers/hooks';
import { Spinner } from '~/atoms';
import {
  InitialView,
  PreferencesView,
  WaitView,
  AreYouSureView,
  MissYouView,
  Surveys,
} from './views';
import type { View } from './types';

type Props = {
  searchBoxBaseUrl: string;
};

export const UnsubscribeMailersFlow = ({ searchBoxBaseUrl }: Props) => {
  const [currentView, setCurrentView] = useState<View>('initial');

  const token = useGetTokenFromUrl();

  const postToken = usePostToken();

  useEffect(() => {
    postToken.mutate({ body: { token } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = useMemo(() => {
    switch (currentView) {
      case 'preferences':
        return (
          <PreferencesView
            setCurrentView={setCurrentView}
            initialData={postToken.data}
          />
        );
      case 'wait':
        return (
          <WaitView
            setCurrentView={setCurrentView}
            initialData={postToken.data}
          />
        );
      case 'areYouSure':
        return (
          <AreYouSureView
            setCurrentView={setCurrentView}
            initialData={postToken.data}
          />
        );
      case 'missYou':
        return (
          <MissYouView
            searchBoxBaseUrl={searchBoxBaseUrl}
            initialData={postToken.data}
          />
        );
      case 'surveys':
        return <Surveys />;
      case 'initial':
      default:
        return (
          <PreferencesView
            setCurrentView={setCurrentView}
            initialData={postToken.data}
          />
        );
    }
  }, [currentView, searchBoxBaseUrl, postToken.data]);

  if (postToken.isLoading) {
    return (
      <div className='flex justify-center items-center min-h-[75vh]'>
        <Spinner isBig color='black' />
      </div>
    );
  }

  return <main>{view}</main>;
};
