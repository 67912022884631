import { useState, useCallback } from 'react';

import { useTranslationContext } from '~/contexts/TranslationContext';
import { usePostHowOftenSurveyAnswer } from '~/UnsubscribeMailers/Api';
import { Radio, Spinner } from '~/atoms';
import {
  ImgSection,
  Header,
  LocalButton,
} from '~/UnsubscribeMailers/App/components';
import { useGetTokenFromUrl } from '~/UnsubscribeMailers/hooks';

export const HowOftenSurvey = () => {
  const [answer, setAnswer] = useState('Three times a week, works best for me');

  const { i18n } = useTranslationContext();

  const postHowOftenSurveyAnswer = usePostHowOftenSurveyAnswer();
  const token = useGetTokenFromUrl();

  const handleAnswerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAnswer(e.target.value);
    },
    []
  );

  const handleClick = () => {
    postHowOftenSurveyAnswer.mutate(
      { body: { answer, token } },
      {
        onSuccess: () => {
          window.location.replace('/');
        },
        onError: (error) => {
          console.error(error);
          alert(i18n.t('javascript.unsubscribe_mailers.api_error'));
        },
      }
    );
  };

  return (
    <>
      <Header
        titles={[
          i18n.t('javascript.unsubscribe_mailers.components.header.title_4'),
          i18n.t('javascript.unsubscribe_mailers.components.header.title_5'),
        ]}
      />
      <section className='wrapper pt-6 pb-8 space-y-10'>
        <h1 className='text-red-1 text-xl text-center font-bold m-0 lg:text-2xl lg:text-left'>
          {i18n.t('javascript.unsubscribe_mailers.how_often_survey.title')}
        </h1>
        <div className='flex flex-col space-y-6'>
          <Radio
            radioId='how-often-survey-radio-1'
            value='Three times a week, works best for me'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t(
              'javascript.unsubscribe_mailers.how_often_survey.answer_1'
            )}
          />
          <Radio
            radioId='how-often-survey-radio-2'
            value='Twice a week is good for me'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t(
              'javascript.unsubscribe_mailers.how_often_survey.answer_2'
            )}
          />
          <Radio
            radioId='how-often-survey-radio-3'
            value='Once a week, please'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t(
              'javascript.unsubscribe_mailers.how_often_survey.answer_3'
            )}
          />
          <Radio
            radioId='how-often-survey-radio-4'
            value='Only once a month'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t(
              'javascript.unsubscribe_mailers.how_often_survey.answer_4'
            )}
          />
          <Radio
            radioId='how-often-survey-radio-5'
            value='Unsubscribe me entirely'
            currentValue={answer}
            onChange={handleAnswerChange}
            label={i18n.t(
              'javascript.unsubscribe_mailers.how_often_survey.answer_5'
            )}
          />
        </div>
        <LocalButton
          onClick={handleClick}
          className='text-white bg-red-1 hover:bg-red-1-darken min-h-[48px] min-w-[103px]'
          isLoading={postHowOftenSurveyAnswer.isLoading}
        >
          {postHowOftenSurveyAnswer.isLoading ? (
            <Spinner color='white' wrapperClassName='justify-center' isBig />
          ) : (
            i18n.t('javascript.unsubscribe_mailers.how_often_survey.button')
          )}
        </LocalButton>
      </section>
      <ImgSection />
    </>
  );
};
