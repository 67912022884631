import { useMutation } from 'react-query';
import axios from 'axios';

type Args = {
  body: {
    answer: string;
    token: string;
  };
};

const postWhySurveyAnswer = ({ body }: Args) =>
  axios.post('/api/v1/unsubscribe_mailers/why', body);

export const usePostWhySurveyAnswer = () => useMutation(postWhySurveyAnswer);
