import { QueryClient, QueryClientProvider } from 'react-query';

import { TranslationContextProvider } from '~/contexts/TranslationContext';
import { SettingsContextProvider } from '~/UnsubscribeMailers/contexts';
import { UnsubscribeMailersFlow } from './UnsubscribeMailersFlow';

const queryClient = new QueryClient();

type Props = {
  defaultUrl: string;
};

export const App = ({ defaultUrl }: Props) => (
  <QueryClientProvider client={queryClient}>
    <TranslationContextProvider>
      <SettingsContextProvider>
        <UnsubscribeMailersFlow searchBoxBaseUrl={defaultUrl} />
      </SettingsContextProvider>
    </TranslationContextProvider>
  </QueryClientProvider>
);
