import axios from 'axios';
import { useMutation } from 'react-query';

import type { FinalizeOrderData } from './types';

const finalizeOrder = async (): Promise<FinalizeOrderData> => {
  const locale = document.querySelector('html')?.lang || 'en';

  // @TODO add external data validation
  const { data } = await axios.post(
    `/api/v1/rebilly/orders/finalize?locale=${locale}`
  );
  return data as FinalizeOrderData;
};

export const useFinalizeOrder = () => useMutation(finalizeOrder);
