import { useState } from 'react';
import { SortCategory } from '~/CampaignTable/App/types';

export const useTooltip = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ right: 0, top: 0 });
  const [hoveredSortCategory, setHoveredSortCategory] =
    useState<SortCategory | null>(null);

  const handleMouseOver = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    sortCategory: SortCategory
  ) => {
    const { height } = e.currentTarget.getBoundingClientRect();
    setPosition({ right: 0, top: height + 5 });
    setIsVisible(true);
    setHoveredSortCategory(sortCategory);
  };

  const handleMouseOut = () => {
    setPosition({ right: 0, top: 0 });
    setIsVisible(false);
    setHoveredSortCategory(null);
  };

  return {
    isVisible,
    position,
    handleMouseOver,
    handleMouseOut,
    hoveredSortCategory,
  };
};
