import { useState } from 'react';

import { TranslationContextProvider } from '~/contexts/TranslationContext';
import { CellWidthContextProvider } from '../contexts';
import type { SortCategory, SortOrder, Data } from './types';
import { MobileFilters } from './MobileFilters';
import { TableHeaders } from './TableHeaders';
import { TableContent } from './TableContent';
import { useMediaQuery } from '~/hooks';
import { useApp } from './useApp';

const MOBILE_BREAKPOINT = 1024;

type Props = {
  data: Data;
  currentCountry: string;
  isLightTheme: boolean;
  i18nPrefix: string;
};

export const App = ({ data, currentCountry, isLightTheme, i18nPrefix }: Props) => {
  const [sortCategory, setSortCategory] = useState<SortCategory>('index');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const [isMobile] = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  const { sortedData, sortCategoriesList } = useApp({
    data: [...data],
    sortCategory,
    sortOrder,
  });

  return (
    <TranslationContextProvider>
      {isMobile && (
        <MobileFilters
          sortCategoriesList={sortCategoriesList}
          sortCategory={sortCategory}
          setSortCategory={setSortCategory}
          currentCountry={currentCountry}
          i18nPrefix={i18nPrefix}
          isLightTheme={isLightTheme}/>
      )}
      <CellWidthContextProvider>
        <div>
          <TableHeaders
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            sortCategoriesList={sortCategoriesList}
            sortCategory={sortCategory}
            setSortCategory={setSortCategory}
            isMobile={isMobile}
            isLightTheme={isLightTheme}
            currentCountry={currentCountry}
            i18nPrefix={i18nPrefix}
          />
          <TableContent
            sortedData={sortedData}
            sortOrder={sortOrder}
            sortCategory={sortCategory}
            isMobile={isMobile}
            isLightTheme={isLightTheme}
          />
        </div>
      </CellWidthContextProvider>
    </TranslationContextProvider>
  );
};
