import { useMutation } from 'react-query';
import axios from 'axios';

import { InitialDataSchema, InitialData } from './types';

type Args = {
  body: { token: string };
};

const postToken = async ({ body }: Args): Promise<InitialData> => {
  const { data } = await axios.post('/api/v1/unsubscribe_mailers/props', body);

  const validationResult = InitialDataSchema.safeParse(data);

  if (!validationResult.success) {
    console.error(validationResult.error);
    throw Error('Data validation error!');
  }

  return validationResult.data;
};

export const usePostToken = () => useMutation(postToken);
