import { useTranslationContext } from '~/contexts/TranslationContext';
import type { PaymentMethod } from '~/SubscriptionCheckout/Api/types';
import { PaymentButton } from '~/SubscriptionCheckout/components';
import type { CurrentView } from '../types';
import styles from './PaymentButtons.module.scss';

type Props = {
  setCurrentView: React.Dispatch<React.SetStateAction<CurrentView>>;
  paymentMethods: PaymentMethod[];
};

export const PaymentButtons = ({ setCurrentView, paymentMethods }: Props) => {
  const { i18n } = useTranslationContext();

  const isPaymentMethodExist = (paymentMethod: PaymentMethod) =>
    paymentMethods.some((method) => method === paymentMethod);

  return (
    <div className={styles.buttonsWrapper}>
      {isPaymentMethodExist('payment-card') && (
        <PaymentButton
          kind='cards'
          caption={i18n.t('javascript.rebilly.subscription.paying_with_card')}
          onClick={() => setCurrentView('card')}
        />
      )}
      {isPaymentMethodExist('payment-sepa') && (
        <PaymentButton
          kind='sepa'
          caption={"Paying with sepa"}
          onClick={() => setCurrentView('sepa')}
        />
      )}
      {isPaymentMethodExist('paypal') && (
        <PaymentButton
          kind='paypal'
          caption={i18n.t('javascript.rebilly.subscription.paying_with_paypal')}
          onClick={() => setCurrentView('paypal')}
        />
      )}
      {isPaymentMethodExist('digital-wallet') && (
        <PaymentButton
          kind='digitalWallet'
          caption={i18n.t('javascript.rebilly.subscription.paying_with_wallet')}
          onClick={() => setCurrentView('wallets')}
        />
      )}
    </div>
  );
};
