import { useState, useEffect } from 'react';
import { ModalDimensions } from './types';

export const useModalDimensions = (createOrderRequestFinished: boolean) => {
  const [modalDimensions, setModalDimensions] = useState<ModalDimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (createOrderRequestFinished) {
      const modal = document.querySelector('.modal-content');
      if (modal && modal instanceof HTMLElement) {
        const { height: modalHeight, width: modalWidth } =
          modal.getBoundingClientRect();
        setModalDimensions({ width: modalWidth, height: modalHeight });
      }
    }
  }, [createOrderRequestFinished]);

  return { modalDimensions };
};
