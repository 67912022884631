import { SortCategoryWithoutIndex, IconName } from '../App/types';

export const getIconName = (
  sortCategoryWithoutIndex: SortCategoryWithoutIndex
): IconName => {
  switch (sortCategoryWithoutIndex) {
    case 'new_arrivals':
      return 'income';
    case 'economy':
      return 'moneytree';
    case 'housing':
      return 'ehouse';
    case 'income':
      return 'payment';
    case 'education_&_employment':
      return 'students';
    case 'leisure':
      return 'culture';
    case 'convenience_&_entertainment':
      return 'cooking'
    case 'community':
      return 'housing'
    case 'work_income_&_benefits':
      return 'labour'
    case 'ecology_&_green_spaces':
      return 'environment'
    case 'living_standard':
      return 'income';
    case 'sport':
      return 'physical_exercise';
    case 'population':
      return 'aging';
    case 'employment':
      return 'labour';
    case 'housing_value':
      return 'housing';
    case 'housing_demand':
      return 'housing';
    case 'family_life':
      return 'housing';
    case 'culture_&_leasure':
      return 'cooking';
    case 'work':
      return 'labour';
    case 'nature_&_greens':
      return 'environment';
    case 'housing_price':
      return 'ehouse';
    case 'quality_of_life':
      return 'housing';
    case 'employment_&_income':
      return 'new_employment';
    case 'crime_&_safety':
      return 'new_crime_and_safety'
    case 'ecology':
      return 'new_ecology'
    case 'housing_prices':
      return 'new_housing_and_prices'
    case 'crime_against_the_person':
      return 'crime7';
    case 'property_crime':
      return 'crime2';
    case 'drugs_and_guns_crime':
      return 'crime3';
    case 'police_department_violence_towards_persons':
      return 'crime4';
    case 'police_performance_property_claim':
      return 'crime5';
    case 'police_performance_drugs_and_guns_crimes':
      return 'crime6';
    case 'family_&_life':
      return 'family_&_life';
    case 'health':
      return 'new_health'
    case 'mathematical_abilities':
      return 'mathematical_abilities';
    case 'linguistic_abilities':
      return 'linguistic_abilities';
    case 'reading':
      return 'box';
    case 'listening':
      return 'bells';
    case 'passing_students':
      return 'candles';
    case 'knowledge_below':
      return 'drum';
    case 'timms_pisa':
      return 'candles';
    case 'national_minimum_standard':
      return 'house';
    case 'performance':
      return 'bells';
    case 'literacy_numeracy':
      return 'bells';
    case 'middle_school_performance':
      return 'drum';
    case 'high_school_performance':
      return 'candles';
    case 'robbery':
      return 'robbery';
    case 'sexual_crimes':
      return 'sexual_crime';
    case 'violent_crimes':
      return 'violent_crime';
    case 'cyber_crime':
      return 'cyber_crime';
    case 'car_crimes':
      return 'car_crime';
    case 'police_response':
      return 'police_response';
    default:
      return sortCategoryWithoutIndex;
  }
};
