import type { ErrorMsg } from './types';

type Props = {
  errorMsg: ErrorMsg;
  className?: string;
};

export const ErrorAlert = ({
  errorMsg: { msg, isHtmlInTranslation, alertClass },
  className,
}: Props) => (
  <>
    {isHtmlInTranslation ? (
      <div
        className={`relative top-0 alert ${alertClass || 'alert-danger'} ${
          className || ''
        }`}
        dangerouslySetInnerHTML={{ __html: msg }}
      />
    ) : (
      <div
        className={`relative top-0 alert ${alertClass || 'alert-danger'} ${
          className || ''
        }`}
      >
        {msg}
      </div>
    )}
  </>
);
