import { Controller } from 'stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = [];
  static intervalId;

  connect = () => {
    const popupClosed = Cookies.get('search-agent-popup-closed');
    const landlordClicked = localStorage.getItem('contact-landlord-clicked');
    const failedAttempt = Cookies.get('search-agent-popup-invalid-data');
    const createdSearchAgent = localStorage.getItem('search-agent-created-via-popup');

    if (popupClosed === undefined &&
      landlordClicked === null &&
      failedAttempt === undefined &&
      createdSearchAgent === null) {
      document.addEventListener('DOMContentLoaded', e => {
        var timer = parseInt(sessionStorage.getItem('sa-popup-timer'));
        if (!timer || timer <= 0) {
          timer = 55
        }
        this.intervalId = setInterval( () => {
          timer--;
          sessionStorage.setItem('sa-popup-timer', timer);
          if(timer === 0) {
            this.togglePopup();
            this.observeModalClose();
            clearInterval(this.intervalId);
          }
        }, 1000)
      });
    }
  }

  disconnect = () => {
    $('#search-agent-modal').off('hidden.bs.modal')
  }

  togglePopup = () => {
    // Toggle via bootstrap #modal(options)
    // see: https://getbootstrap.com/docs/3.4/javascript/#modals-methods
    $('#newAbuseModal').modal('hide');
    $('#search-agent-modal').modal('toggle');
  }

  observeModalClose = () => {
    // Wait for modal to be hidden
    // see: https://getbootstrap.com/docs/3.4/javascript/#modals-events
    $('#search-agent-modal').on('hidden.bs.modal', e => {
      Cookies.set('search-agent-popup-closed', 1, { expires: 7 });
    })
  }
}
