import {
  withFramePayApplePayComponent,
  FramePayApplePayProps,
} from '@rebilly/framepay-react';

type Props = FramePayApplePayProps;

const FramePayApplePayElement = ({ ApplePayElement }: Props) => (
  <ApplePayElement />
);

export const FramePayApplePay = withFramePayApplePayComponent(
  FramePayApplePayElement
);
