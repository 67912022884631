import { ReactNode } from 'react';

import { Button } from '../Button/Button';
import { Spinner } from '~/atoms';
import styles from './SubmitButton.module.scss';

type Props = {
  isLoading: boolean;
  className?: string;
  children?: ReactNode;
};

export const SubmitButton = ({
  isLoading,
  className = '',
  children,
}: Props) => (
  <Button
    type='submit'
    disabled={isLoading}
    className={`${className} ${isLoading ? styles.isLoading : ''}`}
  >
    {isLoading ? (
      <Spinner color='white' wrapperClassName={styles.spinner} isBig />
    ) : (
      children
    )}
  </Button>
);
