import { useState, useCallback } from 'react';
import Cookies from 'js-cookie';

import { useCreatePayment } from '~/SubscriptionCheckout/Api';
import type { PaymentToken } from '~/SubscriptionCheckout/Api/types';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { updateRailsModalBefore3DS } from '~/SubscriptionCheckout/utils/railsModalControl';
import { determineError } from '~/SubscriptionCheckout/utils/determineError';
import { useTrackConversion } from '../useTrackConversion';
import type { ErrorMsg } from '~/SubscriptionCheckout/components/Alerts/ErrorAlert/types';
import type { TrackingData } from '~/SubscriptionCheckout/App/types';

export const usePaymentFlow = () => {
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [urlToOffsiteFlow, setUrlToOffsiteFlow] = useState('');
  const [errorMsg, setErrorMsg] = useState<ErrorMsg>({
    msg: '',
    isHtmlInTranslation: false,
    alertClass: '',
  });
  const [isPaymentSucceeded, setIsPaymentSucceeded] = useState(false);
  const [userDetailsModalMarkup, setUserDetailsModalMarkup] = useState('');

  const createPayment = useCreatePayment();
  const { i18n } = useTranslationContext();
  const { trackConversion } = useTrackConversion();

  const resetErrorMsg = useCallback(() => {
    setErrorMsg({
      msg: '',
      isHtmlInTranslation: false,
      alertClass: '',
    });
  }, []);

  const renderErrorWhenTokenIsInvalid = useCallback(
    (token: PaymentToken) => {
      console.error(token);
      setErrorMsg({
        msg: i18n.t('javascript.rebilly.subscription.error_payment_generic'),
        isHtmlInTranslation: false,
        alertClass: 'alert-danger',
      });
    },
    [i18n]
  );

  const paymentFlow = useCallback(
    (
      tokenId: string,
      modalDimensions: { width: number; height: number },
      trackingData: TrackingData | null
    ) => {
      createPayment.mutate(
        { tokenId },
        {
          // @TODO check statuses and results from api - all request are 200
          onSuccess: (transactionData) => {
            const { status, result, approvalUrl, rebillyError, modal } =
              transactionData;

            Cookies.remove('rebilly_request_id');

            if (rebillyError) {
              const { msg, isHtmlUsed, alertClass } = determineError(
                rebillyError,
                i18n
              );
              setErrorMsg({ msg, isHtmlInTranslation: isHtmlUsed, alertClass });
              console.error(rebillyError);
              return;
            }

            if (
              result === 'abandoned' ||
              result === 'canceled' ||
              result === 'declined'
            ) {
              const { msg, isHtmlUsed, alertClass } = determineError(
                result,
                i18n
              );
              setErrorMsg({ msg, isHtmlInTranslation: isHtmlUsed, alertClass });
              return;
            }

            if (status === 'waiting-approval') {
              if (approvalUrl) {
                setUrlToOffsiteFlow(approvalUrl);
              }
              updateRailsModalBefore3DS(
                modalDimensions.height,
                modalDimensions.width
              );
              setIsIframeVisible(true);
            }

            if (
              (status === 'completed' && result === 'approved') ||
              (status === 'success' && result === 'pass_fast')
            ) {
              if (modal) {
                setUserDetailsModalMarkup(modal);
              }
              if (trackingData) {
                trackConversion(trackingData);
              }
              setIsPaymentSucceeded(true);
            }
          },
          onError: (error) => {
            console.error(error);
            const { msg, isHtmlUsed, alertClass } = determineError(error, i18n);
            setErrorMsg({ msg, isHtmlInTranslation: isHtmlUsed, alertClass });
          },
        }
      );
    },
    [createPayment, i18n, trackConversion]
  );

  return {
    isIframeVisible,
    setIsIframeVisible,
    urlToOffsiteFlow,
    errorMsg,
    setErrorMsg,
    isPaymentSucceeded,
    setIsPaymentSucceeded,
    userDetailsModalMarkup,
    setUserDetailsModalMarkup,
    resetErrorMsg,
    renderErrorWhenTokenIsInvalid,
    paymentFlow,
    isPaymentLoading: createPayment.isLoading,
  };
};
