import { useTranslationContext } from '~/contexts/TranslationContext';
import styles from './SuccessfulPaymentAlert.module.scss';

export const SuccessfulPaymentAlert = () => {
  const { i18n } = useTranslationContext();

  return (
    <div className='alert alert-success relative top-0 text-center mb-3'>
      <h4>{i18n.t('javascript.rebilly.subscription.congratulations')}</h4>
      <span className={styles.alertInfo}>
        {i18n.t('javascript.rebilly.subscription.successfull_payment_info')}
      </span>
    </div>
  );
};
