import { z } from 'zod';

const SuggestionSchema = z.object({
  text: z.string(),
  magicKey: z.string(),
  isCollection: z.boolean(),
});

const SuggestionsSchema = z.object({
  suggestions: z.array(SuggestionSchema),
});

export const SearchResultsSchema = z.object({
  cities: SuggestionsSchema,
  postal_codes: SuggestionsSchema,
  addresses: SuggestionsSchema,
  countries: SuggestionsSchema,
  regions: SuggestionsSchema,
  districts: SuggestionsSchema,
  neighborhoods: SuggestionsSchema,
});

export type Suggestion = z.infer<typeof SuggestionSchema>;

export type SearchResults = z.infer<typeof SearchResultsSchema>;
