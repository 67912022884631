import { useTranslationContext } from '~/contexts/TranslationContext';
import type { InitialData } from '~/UnsubscribeMailers/Api/usePostToken/types';
import {
  ImgSection,
  Header,
  RedBoxSection,
} from '~/UnsubscribeMailers/App/components';
import { Buttons } from './Buttons';
import type { View } from '../../types';

type Props = {
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  initialData: InitialData | undefined;
};

export const WaitView = ({ setCurrentView, initialData }: Props) => {
  const { i18n } = useTranslationContext();

  return (
    <>
      <Header
        titles={[
          i18n.t('javascript.unsubscribe_mailers.components.header.title_1'),
        ]}
        email={initialData?.email ? initialData.email : ''}
      />
      <RedBoxSection>
        <h1 className='text-white text-2xl text-center font-bold m-0 lg:text-4xl'>
          {i18n.t('javascript.unsubscribe_mailers.wait_view.header_1')}
        </h1>
        <div>
          <p className='text-white text-sm text-center m-0 lg:text-xl'>
            {i18n.t('javascript.unsubscribe_mailers.wait_view.paragraph_1')}
          </p>
          <p className='text-white text-sm text-center m-0 lg:text-xl'>
            {i18n.t('javascript.unsubscribe_mailers.wait_view.paragraph_2')}
          </p>
          <p className='text-white text-sm text-center m-0 lg:text-xl'>
            {i18n.t('javascript.unsubscribe_mailers.wait_view.paragraph_3')}
          </p>
        </div>
        <Buttons setCurrentView={setCurrentView} />
      </RedBoxSection>
      <ImgSection />
    </>
  );
};
