import { useCallback } from 'react';

import { useUpdateSettings } from '~/UnsubscribeMailers/Api';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { useSettingsContext } from '~/UnsubscribeMailers/contexts';
import { useGetTokenFromUrl } from '~/UnsubscribeMailers/hooks';
import { Spinner } from '~/atoms';
import { LocalButton } from '~/UnsubscribeMailers/App/components';
import type { View } from '../../../types';

type Props = {
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
};

export const Buttons = ({ setCurrentView }: Props) => {
  const { i18n } = useTranslationContext();

  const { settings } = useSettingsContext();
  const updateSettings = useUpdateSettings();

  const token = useGetTokenFromUrl();

  const handleSaveClick = () => {
    const { searchAgent, ...restSettings } = settings;

    updateSettings.mutate(
      {
        body: { token, search_agent: searchAgent, ...restSettings },
      },
      {
        onSuccess: () => {
          setCurrentView('missYou');
        },
        onError: (error) => {
          console.error(error);
          alert(i18n.t('javascript.unsubscribe_mailers.api_error'));
        },
      }
    );
  };

  const handleRedirect = useCallback(() => {
    window.location.replace('/');
  }, []);

  return (
    <div className='flex justify-center space-x-4'>
      <LocalButton
        className='text-white bg-green-1 hover:bg-green-1-darken'
        onClick={handleRedirect}
        isLoading={updateSettings.isLoading}
      >
        {i18n.t('javascript.unsubscribe_mailers.wait_view.button_1')}
      </LocalButton>
      <LocalButton
        onClick={handleSaveClick}
        className='text-red-1 bg-white hover:bg-white-darken min-h-[48px] min-w-[157px]'
        isLoading={updateSettings.isLoading}
      >
        {updateSettings.isLoading ? (
          <Spinner color='red' wrapperClassName='justify-center' isBig />
        ) : (
          i18n.t('javascript.unsubscribe_mailers.wait_view.button_2')
        )}
      </LocalButton>
    </div>
  );
};
