import { useTranslationContext } from '~/contexts/TranslationContext';
import type { InitialData } from '~/UnsubscribeMailers/Api/usePostToken/types';
import {
  ImgSection,
  Header,
  RedBoxSection,
  SearchBoxSection,
} from '~/UnsubscribeMailers/App/components';

type Props = {
  searchBoxBaseUrl: string;
  initialData: InitialData | undefined;
};

export const MissYouView = ({ searchBoxBaseUrl, initialData }: Props) => {
  const { i18n } = useTranslationContext();

  return (
    <>
      <Header
        titles={[
          i18n.t('javascript.unsubscribe_mailers.components.header.title_1'),
        ]}
        email={initialData?.email ? initialData.email : ''}
      />
      <RedBoxSection>
        <div className='flex flex-col items-center space-y-2'>
          <h1 className='text-white text-2xl text-center font-bold m-0 lg:text-4xl'>
            {i18n.t('javascript.unsubscribe_mailers.miss_you_view.header_1')}
          </h1>
          <span className='text-white text-sm text-center m-0 lg:text-base'>
            (
            {i18n.t('javascript.unsubscribe_mailers.miss_you_view.subheader_1')}
            )
          </span>
        </div>
        <div>
          {initialData?.email && (
            <p className='text-white text-sm text-center font-bold m-0 lg:text-xl'>
              {i18n.t(
                'javascript.unsubscribe_mailers.miss_you_view.paragraph_1',
                { email: initialData.email }
              )}
            </p>
          )}
          <p className='text-white text-sm text-center font-bold m-0 lg:text-xl'>
            {i18n.t('javascript.unsubscribe_mailers.miss_you_view.paragraph_2')}
          </p>
          <p className='text-white text-sm text-center font-bold m-0 lg:text-xl'>
            {i18n.t('javascript.unsubscribe_mailers.miss_you_view.paragraph_3')}
          </p>
        </div>
        <SearchBoxSection
          searchBaseUrl={searchBoxBaseUrl}
          properties={initialData?.properties}
        />
      </RedBoxSection>
      <ImgSection />
    </>
  );
};
