/* eslint-disable react/jsx-props-no-spreading */

type Props = React.ComponentProps<'input'> & {
  radioId: string;
  label: string;
  value: string;
  currentValue: string;
  labelClassName?: string;
};

export const Radio = ({
  radioId,
  label,
  value,
  currentValue,
  labelClassName = '',
  ...props
}: Props) => (
  <div className='flex items-center'>
    <input
      type='radio'
      id={radioId}
      value={value}
      checked={value === currentValue}
      className={`cursor-pointer translate-y-[-1px] m-0${
        props.className ? props.className : ''
      }`}
      {...props}
    />
    <label
      htmlFor={radioId}
      className={`text-base font-normal cursor-pointer mb-0 pl-6 ${labelClassName}`}
    >
      {label}
    </label>
  </div>
);
