import { useState, useMemo } from 'react';

import { WhySurvey } from './Why';
import { HowOftenSurvey } from './HowOften';
import type { Survey } from './types';

export const Surveys = () => {
  const [survey, setSurvey] = useState<Survey>('why');

  const surveyView = useMemo(() => {
    switch (survey) {
      case 'howOften':
        return <HowOftenSurvey />;
      case 'why':
      default:
        return <WhySurvey setSurvey={setSurvey} />;
    }
  }, [survey]);

  return <>{surveyView}</>;
};
