import { useState, useEffect, useCallback } from 'react';
import { FramePayProvider } from '@rebilly/framepay-react';

import { useCreateOrder } from '~/SubscriptionCheckout/Api';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { ErrorAlert } from '~/SubscriptionCheckout/components';
import { Spinner } from '~/atoms';
import { FramePayCard } from './Card';
import { FramePaySepa } from './Sepa';
import { Paypal } from './Paypal';
import { PaymentButtons } from './PaymentButtons';
import { Wallets } from './Wallets';
import type { CurrentView, RebillyConfig, TrackingData } from './types';
import { PRICE_DIVIDER } from '~/SubscriptionCheckout/utils/consts';
import {
  useWalletPayment,
  useModalDimensions,
} from '~/SubscriptionCheckout/hooks';
import styles from './SubscriptionCheckout.module.scss';

type Props = {
  rebillyConfig: RebillyConfig;
};

export const SubscriptionCheckout = ({
  rebillyConfig: { organizationId, publishableKey, websiteId, attribution },
}: Props) => {
  const [currentView, setCurrentView] = useState<CurrentView>('buttons');
  const [trackingData, setTrackingData] = useState<TrackingData | null>(null);
  const [createOrderFinished, setCreateOrderFinished] = useState(false);

  const createOrder = useCreateOrder();
  const { i18n } = useTranslationContext();

  useEffect(() => {
    createOrder.mutate(undefined, {
      onSuccess: ({ plan, user }) => {
        setTrackingData({
          currency: plan.currency,
          price: plan.trialPrice,
          userEmail: user.email,
        });
        setCreateOrderFinished(true);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { modalDimensions } = useModalDimensions(createOrderFinished);

  const {
    errorMsg,
    onTokenReady,
    isPaymentLoading,
    isPaymentSucceeded,
    isIframeVisible,
    urlToOffsiteFlow,
    resetErrorMsg,
  } = useWalletPayment({ trackingData, modalDimensions });

  const backButtonClick = useCallback(() => {
    resetErrorMsg();
    setCurrentView('buttons');
  }, []);

  const renderContent = () => {
    switch (createOrder.status) {
      case 'idle':
      case 'loading':
        return (
          <Spinner
            wrapperClassName={`margin-top-lg ${styles.spinnerWrapper}`}
            color='black'
            isBig
          />
        );
      case 'success': {
        const {
          data: { plan, methods },
        } = createOrder;

        return (
          <FramePayProvider
            injectStyle
            websiteId={websiteId}
            organizationId={organizationId}
            publishableKey={publishableKey}
            transactionData={{
              currency: plan.currency.toUpperCase(),
              amount: plan.trialPrice / PRICE_DIVIDER,
              label: plan.label,
            }}
            onTokenReady={onTokenReady}
            googlePay={{
              buttonType: 'short',
            }}
            paypal={{
              buttonHeight: 44,
            }}
            onError={(error: unknown) => {
              console.error('FramePayProvider.onError', error);
            }}
            placeholders={{
              card: {
                number: '1234 1234 1234 1234',
              },
            }}
            classes={{
              base: styles.framePayWrapper,
            }}
            style={{
              base: {
                fontFamily: 'Nunito, Helvetica, sans-serif',
                color: '#555',
                fontSize: '14px',
                '::placeholder': {
                  color: '#9d9d9d',
                },
              },
            }}
            icon={{
              color: '#9d9d9d',
            }}
          >
            {currentView === 'buttons' && (
              <PaymentButtons
                setCurrentView={setCurrentView}
                paymentMethods={methods}
              />
            )}
            {currentView === 'card' && (
              <FramePayCard
                setCurrentView={setCurrentView}
                paymentData={plan}
                attribution={attribution}
                trackingData={trackingData}
                modalDimensions={modalDimensions}
              />
            )}
            {currentView === 'sepa' && (
              <FramePaySepa
                setCurrentView={setCurrentView}
                paymentData={plan}
                attribution={attribution}
                trackingData={trackingData}
                modalDimensions={modalDimensions}
              />
            )}

            {currentView === 'paypal' && (
              <Paypal
                backButtonClick={backButtonClick}
                errorMsg={errorMsg}
                isPaymentLoading={isPaymentLoading}
                isPaymentSucceeded={isPaymentSucceeded}
                isIframeVisible={isIframeVisible}
                urlToOffsiteFlow={urlToOffsiteFlow}
              />
            )}
            {console.log(urlToOffsiteFlow)}
            {console.log(isIframeVisible)}


            {currentView === 'wallets' && (
              <Wallets
                backButtonClick={backButtonClick}
                errorMsg={errorMsg}
                isPaymentLoading={isPaymentLoading}
                isPaymentSucceeded={isPaymentSucceeded}
                isIframeVisible={isIframeVisible}
                urlToOffsiteFlow={urlToOffsiteFlow}
              />
            )}
          </FramePayProvider>
        );
      }
      case 'error':
      default: {
        console.error(createOrder.error);
        return (
          <ErrorAlert
            errorMsg={{
              msg: i18n.t('javascript.rebilly.create_order_failed'),
              isHtmlInTranslation: false,
              alertClass: 'alert-danger',
            }}
            className={`margin-top-lg ${styles.alert}`}
          />
        );
      }
    }
  };

  return <>{renderContent()}</>;
};
