import { useCallback } from 'react';

import type { SortCategory, SortOrder, Data } from '../types';
import { TableRow } from './TableRow';
import styles from './TableContent.module.scss';

type Props = {
  sortedData: Data;
  sortOrder: SortOrder;
  sortCategory: SortCategory;
  isLightTheme: boolean;
  isMobile: boolean;
};

export const TableContent = ({
  sortedData,
  sortOrder,
  sortCategory,
  isLightTheme,
  isMobile,
}: Props) => {
  const renderRank = useCallback(
    (arrayIndex: number, arrayLength: number) => {
      if (sortOrder === 'desc') {
        return arrayIndex + 1;
      }
      return arrayLength - arrayIndex;
    },
    [sortOrder]
  );

  return (
    <div className={`max-h-[70vh] overflow-y-auto ${styles.tableContent}`}>
      {sortedData.map((item, index) => (
        <TableRow
          key={`${item.id}-${item.city}`}
          item={item}
          rank={renderRank(index, sortedData.length)}
          sortCategory={sortCategory}
          isMobile={isMobile}
          isLightTheme={isLightTheme}
        />
      ))}
    </div>
  );
};
