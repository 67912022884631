type Props = {
  children: React.ReactNode;
  width: number;
};

export const TableHeader = ({ children, width }: Props) => (
  <div
    className='flex justify-center items-center min-h-[40px] text-center text-sm text-dark-1 font-bold px-2'
    style={{ width: `${width}%` }}
  >
    {children}
  </div>
);
