import { ReactNode, RefObject } from 'react';
import styles from './Form.module.scss';

type Props = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  formRef: RefObject<HTMLFormElement>;
  children?: ReactNode;
};

export const Form = ({ handleSubmit, formRef, children }: Props) => (
  <form className={styles.form} onSubmit={handleSubmit} ref={formRef}>
    {children}
  </form>
);
