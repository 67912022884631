import { useCallback } from 'react';
import { TrackingData } from '~/SubscriptionCheckout/App/types';
import { PRICE_DIVIDER } from '~/SubscriptionCheckout/utils/consts';

export const useTrackConversion = () => {
  const trackConversion = useCallback((trackingData: TrackingData) => {
    const { currency, price, userEmail } = trackingData;
    
    if (!localStorage.getItem('track_trial_conversion_executed')) {
      localStorage.setItem('track_trial_conversion_executed', 'true');
      window.dataLayer.push({
        event: 'premium_signup',
        currency,
        payment_method: "Rebilly",
        price: price / PRICE_DIVIDER,
        user_data: {
          email: userEmail,
        },
      });
    }
  }, []);

  return { trackConversion };
};
