type Props = {
  children: React.ReactNode;
  width: number;
  isLightTheme: boolean;
};

export const TableCell = ({ children, isLightTheme, width }: Props) => (
  <span
    className={`flex justify-center items-center text-center text-sm px-2${
        isLightTheme ? ' text-black' : ' text-dark-1'
    }`}
    style={{ width: `${width}%` }}
  >
    {children}
  </span>
);
