/* eslint-disable no-restricted-syntax */

import ReactDOM from 'react-dom';
import React from 'react';
import { AttributeObserver } from '../libs/AttributeObserver';

import { SampleApp } from './SampleApp/App';
import { App as SubscriptionCheckout } from './SubscriptionCheckout/App';
import { CheckoutRedirect } from './CheckoutRedirect/App';
import { App as SearchBox } from './SearchBox/App';
import { App as CampaignTable } from './CampaignTable/App';
import { App as UnsubscribeMailers } from './UnsubscribeMailers/App';

export const store = {
  SampleApp,
  SubscriptionCheckout,
  CheckoutRedirect,
  SearchBox,
  CampaignTable,
  UnsubscribeMailers,
};

export const initializeApp = (element: Element) => {
  if (element.textContent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const props = JSON.parse(element.textContent);
    const name = element.getAttribute('data-react-app-name')!;
    const mountpoint = document.getElementById(
      element.getAttribute('data-react-app-mountpoint-id')!
    )!;
    const app = store[name];

    ReactDOM.render(React.createElement(app, props, null), mountpoint);
  }
};

const observer = new AttributeObserver(
  document.querySelector('html')!,
  'data-react-app-name',
  {
    elementMatchedAttribute: (element, attributeName) => {
      initializeApp(element);
    },
  }
);

document.addEventListener(
  'DOMContentLoaded',
  () => {
    for (const element of document.body.querySelectorAll(observer.selector)) {
      initializeApp(element);
    }
    observer.start();
  },
  false
);
