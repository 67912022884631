import { useCallback } from 'react';
import { I18n } from 'i18n-js/typings';

import { useUpdateSettings } from '~/UnsubscribeMailers/Api';
import { useGetTokenFromUrl } from '~/UnsubscribeMailers/hooks';
import { useSettingsContext } from '~/UnsubscribeMailers/contexts';
import type { View } from '../../types';

type Args = {
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  i18n: I18n;
};

export const useAreYouSure = ({ setCurrentView, i18n }: Args) => {
  const { settings } = useSettingsContext();
  const updateSettings = useUpdateSettings();

  const token = useGetTokenFromUrl();

  const handleLinkClick = useCallback(() => {
    setCurrentView('preferences');
  }, [setCurrentView]);

  const handleUnsubscribeClick = () => {
    const { searchAgent, ...restSettings } = settings;

    updateSettings.mutate(
      {
        body: { token, search_agent: searchAgent, ...restSettings },
      },
      {
        onSuccess: () => {
          setCurrentView('surveys');
        },
        onError: (error) => {
          console.error(error);
          alert(i18n.t('javascript.unsubscribe_mailers.api_error'));
        },
      }
    );
  };

  return {
    handleLinkClick,
    handleUnsubscribeClick,
    isLoading: updateSettings.isLoading,
  };
};
