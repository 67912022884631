import {
  FramePayIBANProps,
  withFramePayIBANComponent,
} from '@rebilly/framepay-react';

import { IframeLoadingSpinner } from './IframeLoadingSpinner/IframeLoadingSpinner';
import type {
  SepaIframeReady,
  SepaDetailsComplete,
  SepaElementOnChangeEventData,
} from '../types';
import styles from './FramePaySepaElements.module.scss';

type Props = FramePayIBANProps & {
  isSepaReady: SepaIframeReady;
  setIsSepaReady: React.Dispatch<React.SetStateAction<SepaIframeReady>>;
  setSepaDetailsComplete: React.Dispatch<
    React.SetStateAction<SepaDetailsComplete>
  >;
};

const SepaElements = ({
  IBANElement,
  isSepaReady,
  setIsSepaReady,
  setSepaDetailsComplete,
}: Props) => (
  <div className={styles.ibanWrapper}>
    {!isSepaReady.ibanNumber && (
      <IframeLoadingSpinner wrapperClassName={styles.ibanNumber} />
    )}
    <div
      className={`${styles.inputWrapper} ${styles.ibanNumber} ${
        !isSepaReady.ibanNumber ? styles.notReady : ''
      }`}
    >
      <IBANElement
        onReady={() =>
          setIsSepaReady((prevValues) => ({
            ...prevValues,
            ibanNumber: true,
          }))
        }
        onChange={(data: SepaElementOnChangeEventData) => {
          setSepaDetailsComplete((prevValues) => ({
            ...prevValues,
            ibanNumber: data.completed,
          }));
        }}
      />
    </div>
  </div>
);

export const FramePaySepaElements = withFramePayIBANComponent(SepaElements);
