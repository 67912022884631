import { useTranslationContext } from '~/contexts/TranslationContext';
import {
  BackButton,
  SuccessfulPaymentAlert,
  ErrorAlert,
  Iframe,
} from '~/SubscriptionCheckout/components';
import { Spinner } from '~/atoms';
import type { ErrorMsg } from '~/SubscriptionCheckout/components/Alerts/ErrorAlert/types';
import { FramePayPaypal } from './FramePayPaypal';
import styles from './Paypal.module.scss';

type Props = {
  errorMsg: ErrorMsg;
  isPaymentLoading: boolean;
  isPaymentSucceeded: boolean;
  isIframeVisible: boolean;
  urlToOffsiteFlow: string;
  backButtonClick: () => void;
};

export const Paypal = ({
  errorMsg,
  isPaymentLoading,
  isPaymentSucceeded,
  isIframeVisible,
  urlToOffsiteFlow,
  backButtonClick,
}: Props) => {
  const { i18n } = useTranslationContext();

  if (isPaymentLoading) {
    return (
      <div className={`margin-top-lg ${styles.loadingTextWrapper}`}>
        <div className='margin-bottom-md'>
          {i18n.t('javascript.rebilly.subscription.payment_loading')}
        </div>
        <Spinner color='black' isBig />
      </div>
    );
  }

  return (
    <>
      {isPaymentSucceeded && (
        <div className='margin-top-lg'>
          <SuccessfulPaymentAlert />
        </div>
      )}
      <div
        className={`margin-top-lg ${isPaymentSucceeded ? 'hidden' : 'block'}`}
      >
        <BackButton onClick={backButtonClick} disabled={isPaymentLoading} />
        {errorMsg.msg && <ErrorAlert errorMsg={errorMsg} />}
        <div className={styles.inputWrapper}>
          <Spinner
            color='black'
            isBig
            wrapperClassName='justify-center absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]'
          />
          <FramePayPaypal />
        </div>
      </div>
      {isIframeVisible && urlToOffsiteFlow && (
        <Iframe sourceUrl={urlToOffsiteFlow} title='Offsite payment flow' />
      )}
    </>
  );
};
