import { SearchBox } from '~/SearchBox/App/SearchBox';
import { useTranslationContext } from '~/contexts/TranslationContext';

type Props = {
  searchBaseUrl: string;
  properties: number | undefined;
};

export const SearchBoxSection = ({ searchBaseUrl, properties }: Props) => {
  const { i18n } = useTranslationContext();

  return (
    <div className='flex flex-col items-center space-y-2'>
      {properties && (
        <span
          className='text-white text-center lg:text-xl'
          dangerouslySetInnerHTML={{
            __html: i18n.t(
              'javascript.unsubscribe_mailers.components.search_box.title',
              {
                properties,
              }
            ),
          }}
        />
      )}
      <div className='w-full'>
        <SearchBox searchBaseUrl={searchBaseUrl} />
      </div>
    </div>
  );
};
