import axios from 'axios';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

import type { TransactionStatus } from './types';

type BodySchema = {
  tokenId: string;
};

const createPayment = async ({
  tokenId,
}: BodySchema): Promise<TransactionStatus> => {
  // set rebilly_request_id cookie to prevent duplicated transactions
  const uuid = Cookies.get('rebilly_request_id') || uuidv4();
  const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
  Cookies.set('rebilly_request_id', uuid, { expires: inFiveMinutes });

  const locale = document.querySelector('html')?.lang || 'en';

  // @TODO add external data validation
  const { data } = await axios.post(
    `/api/v1/rebilly/orders/pay?locale=${locale}`,
    {
      token: tokenId,
    }
  );
  return data as TransactionStatus;
};

export const useCreatePayment = () => useMutation(createPayment);
