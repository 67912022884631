import {
  FramePayCardProps,
  withFramePayCardComponent,
} from '@rebilly/framepay-react';

import { IframeLoadingSpinner } from './IframeLoadingSpinner/IframeLoadingSpinner';
import type {
  CardIframeReady,
  CardDetailsComplete,
  CardElementOnChangeEventData,
} from '../types';
import styles from './FramePayCardElements.module.scss';

type Props = FramePayCardProps & {
  isCardReady: CardIframeReady;
  setIsCardReady: React.Dispatch<React.SetStateAction<CardIframeReady>>;
  setCardDetailsComplete: React.Dispatch<
    React.SetStateAction<CardDetailsComplete>
  >;
};

const CardElements = ({
  CardNumberElement,
  CardExpiryElement,
  CardCvvElement,
  isCardReady,
  setIsCardReady,
  setCardDetailsComplete,
}: Props) => (
  <div className={styles.cardWrapper}>
    {!isCardReady.cardNumber && (
      <IframeLoadingSpinner wrapperClassName={styles.cardNumber} />
    )}
    <div
      className={`${styles.inputWrapper} ${styles.cardNumber} ${
        !isCardReady.cardNumber ? styles.notReady : ''
      }`}
    >
      <CardNumberElement
        onReady={() =>
          setIsCardReady((prevValues) => ({
            ...prevValues,
            cardNumber: true,
          }))
        }
        onChange={(data: CardElementOnChangeEventData) => {
          setCardDetailsComplete((prevValues) => ({
            ...prevValues,
            cardNumber: data.completed,
          }));
        }}
      />
    </div>
    {!isCardReady.expiry && (
      <IframeLoadingSpinner wrapperClassName={styles.cardExpiry} />
    )}
    <div
      className={`${styles.inputWrapper} ${styles.cardExpiry} ${
        !isCardReady.expiry ? styles.notReady : ''
      }`}
    >
      <CardExpiryElement
        onReady={() =>
          setIsCardReady((prevValues) => ({
            ...prevValues,
            expiry: true,
          }))
        }
        onChange={(data: CardElementOnChangeEventData) => {
          setCardDetailsComplete((prevValues) => ({
            ...prevValues,
            expiry: data.completed,
          }));
        }}
      />
    </div>
    {!isCardReady.cvv && (
      <IframeLoadingSpinner wrapperClassName={styles.cardCVV} />
    )}
    <div
      className={`${styles.inputWrapper} ${styles.cardCVV} ${
        !isCardReady.cvv ? styles.notReady : ''
      }`}
    >
      <CardCvvElement
        onReady={() =>
          setIsCardReady((prevValues) => ({
            ...prevValues,
            cvv: true,
          }))
        }
        onChange={(data: CardElementOnChangeEventData) => {
          setCardDetailsComplete((prevValues) => ({
            ...prevValues,
            cvv: data.completed,
          }));
        }}
      />
    </div>
  </div>
);

export const FramePayCardElements = withFramePayCardComponent(CardElements);
