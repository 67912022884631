import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string) => {
  const getMatches = (queryToCheck: string) =>
    window.matchMedia(queryToCheck).matches;

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    const mql = window.matchMedia(query);

    const handleChange = () => {
      setMatches(getMatches(query));
    };

    try {
      mql.addEventListener('change', handleChange);
    } catch {
      mql.addListener(handleChange);
    }

    return () => {
      try {
        mql.removeEventListener('change', handleChange);
      } catch {
        mql.removeListener(handleChange);
      }
    };
  }, [query]);

  return [matches];
};
