import ReactDOM from 'react-dom';

import styles from './Iframe.module.scss';

type Props = {
  sourceUrl: string;
  title: string;
};

export const Iframe = ({ sourceUrl, title }: Props) => {
  const portalContainer = document.querySelector('.modal-content');
  window.localStorage.setItem('rebilly-redirect-back', window.location);

  return (
    <>
      {portalContainer &&
        ReactDOM.createPortal(
          <iframe src={sourceUrl} title={title} className={styles.iframe} />,
          portalContainer
        )}
    </>
  );
};
