import { useTranslationContext } from '~/contexts/TranslationContext';
import { LocalButton } from '~/UnsubscribeMailers/App/components';
import type { View } from '~/UnsubscribeMailers/App/types';
import type { Settings } from '~/UnsubscribeMailers/contexts/SettingsContext/types';

type Props = {
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  settings: Settings;
};

export const SaveButton = ({ setCurrentView, settings }: Props) => {
  const { i18n } = useTranslationContext();

  const handleClick = () => {
    if (Object.values(settings).every((setting) => !setting)) {
      setCurrentView('areYouSure');
    } else if (Object.values(settings).some((setting) => !setting)) {
      setCurrentView('wait');
    } else {
      window.location.replace('/');
    }
  };

  return (
    <div className='wrapper text-right'>
      <LocalButton
        onClick={handleClick}
        className='text-white bg-red-1 hover:bg-red-1-darken'
      >
        {i18n.t('javascript.unsubscribe_mailers.preferences_view.button')}
      </LocalButton>
    </div>
  );
};
