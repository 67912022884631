import { useTranslationContext } from '~/contexts/TranslationContext';
import { Button } from '../Button/Button';
import styles from './BackButton.module.scss';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

export const BackButton = ({ onClick, disabled = false }: Props) => {
  const { i18n } = useTranslationContext();

  return (
    <div className={styles.backButtonWrapper}>
      <Button
        onClick={onClick}
        className={`${styles.backButton} ${disabled ? styles.isLoading : ''}`}
        disabled={disabled}
      >
        <i
          className='fa fa-long-arrow-left margin-right-sm'
          aria-hidden='true'
        />
        <span className='uppercase'>
          {i18n.t('javascript.rebilly.subscription.back')}
        </span>
      </Button>
    </div>
  );
};
