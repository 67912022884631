import { createContext, useContext, useState, useCallback } from 'react';

const useCellWidth = () => {
  const [cellWidth, setCellWidth] = useState(0);

  const getCellWidth = useCallback((width: number) => setCellWidth(width), []);

  return { cellWidth, getCellWidth };
};

type UseCellWidth = ReturnType<typeof useCellWidth>;

const CellWidthContext = createContext<UseCellWidth | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const CellWidthContextProvider = ({ children }: Props) => (
  <CellWidthContext.Provider value={useCellWidth()}>
    {children}
  </CellWidthContext.Provider>
);

export const useCellWidthContext = () => {
  const context = useContext(CellWidthContext);
  if (context === undefined) {
    throw new Error(
      'useCellWidthContext must be within CellWidthContextProvider'
    );
  }
  return context;
};
