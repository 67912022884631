import { useTranslationContext } from '~/contexts/TranslationContext';
import type { InitialData } from '~/UnsubscribeMailers/Api/usePostToken/types';
import { Button, Spinner } from '~/atoms';
import {
  ImgSection,
  Header,
  RedBoxSection,
  LocalButton,
} from '~/UnsubscribeMailers/App/components';
import { useAreYouSure } from './useAreYouSure';
import type { View } from '../../types';

type Props = {
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  initialData: InitialData | undefined;
};

export const AreYouSureView = ({ setCurrentView, initialData }: Props) => {
  const { i18n } = useTranslationContext();

  const { handleLinkClick, handleUnsubscribeClick, isLoading } = useAreYouSure({
    setCurrentView,
    i18n,
  });

  return (
    <>
      <Header
        titles={[
          i18n.t('javascript.unsubscribe_mailers.components.header.title_1'),
        ]}
        email={initialData?.email ? initialData.email : ''}
      />
      <RedBoxSection>
        <h1 className='text-white text-2xl text-center font-bold m-0 lg:text-4xl'>
          {i18n.t('javascript.unsubscribe_mailers.are_you_sure_view.header_1')}
        </h1>
        <p className='text-white text-sm text-center m-0 lg:text-xl'>
          {i18n.t(
            'javascript.unsubscribe_mailers.are_you_sure_view.paragraph_1'
          )}
        </p>
        <div className='text-white text-base text-center font-bold lg:text-2xl'>
          <span>
            {i18n.t('javascript.unsubscribe_mailers.are_you_sure_view.text')}{' '}
          </span>
          <Button
            onClick={handleLinkClick}
            className='text-blue-2 hover:underline'
            disabled={isLoading}
          >
            {i18n.t('javascript.unsubscribe_mailers.are_you_sure_view.link')}.
          </Button>
        </div>
        <div className='flex justify-center space-x-4'>
          <LocalButton
            className='text-white bg-green-1 hover:bg-green-1-darken'
            onClick={handleLinkClick}
            isLoading={isLoading}
          >
            {i18n.t(
              'javascript.unsubscribe_mailers.are_you_sure_view.button_1'
            )}
          </LocalButton>
          <LocalButton
            onClick={handleUnsubscribeClick}
            className='text-red-1 bg-white hover:bg-white-darken min-h-[48px] min-w-[144px]'
          >
            {isLoading ? (
              <Spinner color='red' wrapperClassName='justify-center' isBig />
            ) : (
              i18n.t(
                'javascript.unsubscribe_mailers.are_you_sure_view.button_2'
              )
            )}
          </LocalButton>
        </div>
      </RedBoxSection>
      <ImgSection />
    </>
  );
};
