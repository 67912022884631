import { Controller } from 'stimulus';
import { triangleTextProportions } from './utils';

export default class extends Controller {
  static targets = ['img', 'triangle', 'text'];

  triangleProportion = 1 / 4;
  resizeObserver;

  connect = async () => {
    if (this.hasTriangleTarget && this.hasTextTarget) {
      const size = await this.getImgSize();
      if (size) {
        this.makeTriangleVisible();
        this.setTriangleSize(size * this.triangleProportion);
        this.setTextSize(
          size * this.getTextProportion(),
          size * this.getDistanceProportion()
        );
        this.watchResize();
      }
    }
  };

  getTextProportion = () => {
    const { textProportion } =
      triangleTextProportions[window.location.hostname][
        this.getCurrentLocale()
      ];
    return textProportion;
  };

  getDistanceProportion = () => {
    const { distanceProportion } =
      triangleTextProportions[window.location.hostname][
        this.getCurrentLocale()
      ];
    return distanceProportion;
  };

  getCurrentLocale = () => {
    const htmlElement = document.querySelector('html');
    const currentLocale = htmlElement?.lang || 'en';
    return currentLocale;
  };

  getImgSize = async () => {
    const img = await this.getImg();
    const imgWidth = img.clientWidth;
    const imgHeight = img.clientHeight;
    const size = imgWidth > imgHeight ? imgHeight : imgWidth;

    return size;
  };

  getImg = async () => {
    const img = this.imgTarget;
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
    });
  };

  makeTriangleVisible = () => {
    const triangle = this.triangleTarget;
    triangle.classList.replace('hidden', 'flex');
  };

  setTriangleSize = (size) => {
    const triangle = this.triangleTarget;
    triangle.style.width = `${size}px`;
    triangle.style.height = `${size}px`;
  };

  setTextSize = (fontSize, distance) => {
    const text = this.textTarget;
    text.style.fontSize = `${fontSize}px`;
    text.style.transform = `rotate(-45deg) translateY(${distance}px)`;
  };

  watchResize = () => {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        const size = width > height ? height : width;
        this.setTriangleSize(size * this.triangleProportion);
        this.setTextSize(
          size * this.getTextProportion(),
          size * this.getDistanceProportion()
        );
      }
    });
    this.resizeObserver.observe(this.imgTarget);
  };

  disconnect = () => {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  };
}
