import styles from './Spinner.module.scss';

type Props = {
  color: 'white' | 'black' | 'grey' | 'red';
  wrapperClassName?: string;
  className?: string;
  isBig?: boolean;
};

export const Spinner = ({
  color,
  wrapperClassName,
  className,
  isBig = false,
}: Props) => {
  const wrapperCSS = wrapperClassName ? ` ${wrapperClassName}` : '';

  const getColor = () => {
    switch (color) {
      case 'black':
        return 'border-black';
      case 'grey':
        return 'border-grey-3';
      case 'red':
        return 'border-red-1';
      default:
        return 'border-white';
    }
  };

  return (
    <span className={`flex${wrapperCSS}`}>
      <div
        className={`${styles.loader}${className ? ` ${className}` : ''}${
          isBig ? ` ${styles.bigger}` : ''
        } ${getColor()}`}
      />
    </span>
  );
};
