import { useState, useEffect } from 'react';

import { useTranslationContext } from '~/contexts/TranslationContext';
import {
  BackButton,
  SuccessfulPaymentAlert,
  ErrorAlert,
  Iframe,
} from '~/SubscriptionCheckout/components';
import { Spinner } from '~/atoms';
import type { ErrorMsg } from '~/SubscriptionCheckout/components/Alerts/ErrorAlert/types';
import { FramePayGooglePay } from './FramePayGooglePay';
import { FramePayApplePay } from './FramePayApplePay';
import styles from './Wallets.module.scss';

type Props = {
  errorMsg: ErrorMsg;
  isPaymentLoading: boolean;
  isPaymentSucceeded: boolean;
  isIframeVisible: boolean;
  urlToOffsiteFlow: string;
  backButtonClick: () => void;
};

export const Wallets = ({
  errorMsg,
  isPaymentLoading,
  isPaymentSucceeded,
  isIframeVisible,
  urlToOffsiteFlow,
  backButtonClick,
}: Props) => {
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);

  useEffect(() => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setIsApplePayAvailable(true);
    }
  }, []);

  const { i18n } = useTranslationContext();

  if (isPaymentLoading) {
    return (
      <div className={`margin-top-lg ${styles.loadingTextWrapper}`}>
        <div className='margin-bottom-md'>
          {i18n.t('javascript.rebilly.subscription.payment_loading')}
        </div>
        <Spinner color='black' isBig />
      </div>
    );
  }

  return (
    <>
      {isPaymentSucceeded && (
        <div className='margin-top-lg'>
          <SuccessfulPaymentAlert />
        </div>
      )}
      <div
        className={`margin-top-lg ${isPaymentSucceeded ? 'hidden' : 'block'}`}
      >
        <BackButton onClick={backButtonClick} disabled={isPaymentLoading} />
        {errorMsg.msg && <ErrorAlert errorMsg={errorMsg} />}
        <div className={styles.googlePayWrapper}>
          <FramePayGooglePay />
        </div>
        {isApplePayAvailable && (
          <div className={styles.applePayWrapper}>
            <FramePayApplePay />
          </div>
        )}
      </div>
      {isIframeVisible && urlToOffsiteFlow && (
        <Iframe sourceUrl={urlToOffsiteFlow} title='Offsite payment flow' />
      )}
    </>
  );
};
