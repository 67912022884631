import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { useFinalizeOrder } from '~/SubscriptionCheckout/Api';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { updateRailsModalAfter3DS } from '~/SubscriptionCheckout/utils/railsModalControl';
import type { ErrorMsg } from '~/SubscriptionCheckout/components/Alerts/ErrorAlert/types';
import type { TrackingData } from '~/SubscriptionCheckout/App/types';
import { useTrackConversion } from '../useTrackConversion';

type Params = {
  setIsIframeVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setUserDetailsModalMarkup: React.Dispatch<React.SetStateAction<string>>;
  setIsPaymentSucceeded: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMsg: React.Dispatch<React.SetStateAction<ErrorMsg>>;
  modalDimensions: { width: number; height: number };
  trackingData: TrackingData | null;
};

export const useFlowAfter3dsComplete = ({
  setIsIframeVisible,
  setUserDetailsModalMarkup,
  setIsPaymentSucceeded,
  setErrorMsg,
  modalDimensions,
  trackingData,
}: Params) => {
  const finalizeOrder = useFinalizeOrder();
  const { i18n } = useTranslationContext();
  const { trackConversion } = useTrackConversion();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === '3DS-authentication-complete') {
        setIsIframeVisible(false);
        updateRailsModalAfter3DS(modalDimensions.height, modalDimensions.width);

        finalizeOrder.mutate(undefined, {
          onSuccess: (data) => {
            if (data.status === 'active') {
              if (data.modal) {
                setUserDetailsModalMarkup(data.modal);
              }
              if (trackingData) {
                trackConversion(trackingData);
              }
              setIsPaymentSucceeded(true);
            } else {
              const failedPaymentAttempts =
                Number(Cookies.get('failed_payment_attempts_counter')) || 0;

              if (
                !Number.isNaN(failedPaymentAttempts) &&
                failedPaymentAttempts > 1
              ) {
                setErrorMsg({
                  msg: `<p class="text-sm mb-4">${i18n.t(
                    'javascript.rebilly.subscription.error_second_payment_failure_1'
                  )}</p>
                    <p class="text-sm italic">${i18n.t(
                      'javascript.rebilly.subscription.error_second_payment_failure_2',
                      {
                        email:
                          document.querySelector('html')?.dataset.mailto ||
                          'info@rentola.com',
                      }
                    )}</p>`,
                  isHtmlInTranslation: true,
                  alertClass: 'alert-danger',
                });
              } else {
                setErrorMsg({
                  msg: i18n.t(
                    'javascript.rebilly.subscription.error_payment_generic'
                  ),
                  isHtmlInTranslation: false,
                  alertClass: 'alert-danger',
                });
              }
            }
          },

          onError: (error) => {
            setErrorMsg({
              msg: i18n.t(
                'javascript.rebilly.subscription.error_payment_generic'
              ),
              isHtmlInTranslation: false,
              alertClass: 'alert-danger',
            });
            console.error(error);
          },
        });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalDimensions.height,
    modalDimensions.width,
    finalizeOrder,
    trackConversion,
  ]);
};
