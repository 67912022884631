import {
  withFramePayPaypalComponent,
  FramePayPaypalProps,
} from '@rebilly/framepay-react';

type Props = FramePayPaypalProps;

const FramePayPaypalElement = ({ PaypalElement }: Props) => <PaypalElement />;

export const FramePayPaypal = withFramePayPaypalComponent(
  FramePayPaypalElement
);
