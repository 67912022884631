import { useCellWidthContext } from '~/CampaignTable/contexts';
import type { SortOrder, SortCategory } from '../../types';
import { TableHeader } from '~/CampaignTable/components';
import { SingleHeader } from './SingleHeader';

type Props = {
  sortOrder: SortOrder;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
  sortCategoriesList: SortCategory[];
  sortCategory: SortCategory;
  setSortCategory: React.Dispatch<React.SetStateAction<SortCategory>>;
  currentCountry: string;
  isLightTheme: boolean;
  i18nPrefix: string;
};

export const DesktopTableHeaders = ({
  sortOrder,
  setSortOrder,
  sortCategoriesList,
  sortCategory,
  setSortCategory,
  currentCountry,
  i18nPrefix,
  isLightTheme,
}: Props) => {
  const { cellWidth } = useCellWidthContext();

  return (
    <>
      {sortCategoriesList.map((sortCategoryElement) => (
        <TableHeader key={sortCategoryElement} width={cellWidth}>
          <SingleHeader
            sortCategoryElement={sortCategoryElement}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            isActive={sortCategoryElement === sortCategory}
            setSortCategory={setSortCategory}
            currentCountry={currentCountry}
            i18nPrefix={i18nPrefix}
            isLightTheme={isLightTheme}/>
        </TableHeader>
      ))}
    </>
  );
};
