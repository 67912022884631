import { useState } from 'react';
import { Button } from '~/SampleApp/components';

type Props = {
  text: string;
};

export const SampleApp = ({ text }: Props) => {
  const [count, setCount] = useState(0);

  return (
    <div className='App'>
      <Button text='hello' onClick={() => console.log('hello')} />
      <header className='App-header'>
        <p>Hello Vite + React!</p>
        <p>{text}</p>
        <p>
          <button
            type='button'
            onClick={() => setCount((prevCount) => prevCount + 1)}
          >
            count is: {count}
          </button>
        </p>
        <p>
          Edit <code>components/SampleApp/index.tsx</code> and save to test HMR
          updates.
        </p>
        <p>
          <a
            className='App-link'
            href='https://reactjs.org'
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn React
          </a>
          {' | '}
          <a
            className='App-link'
            href='https://vitejs.dev/guide/features.html'
            target='_blank'
            rel='noopener noreferrer'
          >
            Vite Docs
          </a>
        </p>
      </header>
    </div>
  );
};
