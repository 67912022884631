/* eslint-disable react/jsx-props-no-spreading */

type Props = React.ComponentProps<'button'> & {
  children: React.ReactNode;
};

export const Button = ({ children, ...rest }: Props) => (
  <button type='button' {...rest}>
    {children}
  </button>
);
