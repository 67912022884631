import type { PaymentToken } from '~/SubscriptionCheckout/Api/types';
import {
  useFlowAfterSuccessfulPayment,
  useFlowAfter3dsComplete,
  usePaymentFlow,
} from '~/SubscriptionCheckout/hooks';
import type { TrackingData } from '~/SubscriptionCheckout/App/types';
import { ModalDimensions } from '../useModalDimensions/types';

type Args = {
  trackingData: TrackingData | null;
  modalDimensions: ModalDimensions;
};

export const useWalletPayment = ({ trackingData, modalDimensions }: Args) => {
  const {
    isIframeVisible,
    setIsIframeVisible,
    urlToOffsiteFlow,
    errorMsg,
    setErrorMsg,
    isPaymentSucceeded,
    setIsPaymentSucceeded,
    userDetailsModalMarkup,
    setUserDetailsModalMarkup,
    resetErrorMsg,
    renderErrorWhenTokenIsInvalid,
    paymentFlow,
    isPaymentLoading,
  } = usePaymentFlow();

  const onTokenReady = (token: PaymentToken) => {
    resetErrorMsg();

    if (!token.id) {
      renderErrorWhenTokenIsInvalid(token);
      return;
    }

    paymentFlow(token.id, modalDimensions, trackingData);
  };

  useFlowAfter3dsComplete({
    setIsIframeVisible,
    setUserDetailsModalMarkup,
    setIsPaymentSucceeded,
    setErrorMsg,
    modalDimensions,
    trackingData,
  });

  useFlowAfterSuccessfulPayment(isPaymentSucceeded, userDetailsModalMarkup);

  return {
    errorMsg,
    onTokenReady,
    isPaymentLoading,
    isPaymentSucceeded,
    isIframeVisible,
    urlToOffsiteFlow,
    resetErrorMsg,
  };
};
