/* eslint-disable @typescript-eslint/no-unsafe-return */

import { Button } from '../Button/Button';
import styles from './PaymentButton.module.scss';
import cardsImg from '~/SubscriptionCheckout/assets/images/cards.png';
import sepaImg from '~/SubscriptionCheckout/assets/images/sepa.png';
import paypalImg from '~/SubscriptionCheckout/assets/images/paypal.png';
import walletsImg from '~/SubscriptionCheckout/assets/images/wallets.png';

type Props = {
  kind: 'cards' | 'paypal' | 'digitalWallet' | 'sepa';
  caption: string;
  onClick: () => void;
};

export const PaymentButton = ({ kind, caption, onClick }: Props) => {
  const chooseImgSrc = () => {
    switch (kind) {
      case 'cards':
        return cardsImg;
      case 'sepa':
        return sepaImg;
      case 'paypal':
        return paypalImg;
      case 'digitalWallet':
        return walletsImg;
      default:
        throw new Error(`Unexpected value: ${kind}. Should have been never.`);
    }
  };

  const renderAlt = () => {
    switch (kind) {
      case 'cards':
        return 'Cards';
      case 'sepa':
        return 'Sepa';
      case 'paypal':
        return 'PayPal';
      case 'digitalWallet':
        return 'Digital Wallet';
      default:
        throw new Error(`Unexpected value: ${kind}. Should have been never.`);
    }
  };

  return (
    <div className={`${styles.wrapper} margin-top-lg`}>
      <Button onClick={onClick} className={styles.btn}>
        <img src={chooseImgSrc()} alt={renderAlt()} />
      </Button>
      <h4 className={styles.caption}>{caption}</h4>
    </div>
  );
};
