import { Controller } from 'stimulus';
import { percentageToRunEzoic, doesHostAllowsSplitTesting } from './utils';

export default class extends Controller {
  static targets = ['placeholder'];

  connect = () => {
    const { placeholders, validIds } = this.getValidIds();

    window.ezstandalone = window.ezstandalone || {};
    window.ezstandalone.cmd = window.ezstandalone.cmd || [];
    window.ezstandalone.cmd.push(() => {
      console.log("Ezoic cmd.push calling");

      this.toggleAnchorAds();
      if (this.shouldUserSeeAds()) {
        this.initializeAndShowAds(validIds);
      } else {
        this.hidePlaceholders(placeholders);
      }
    });
  };

  getValidIds() {
    const placeholders = this.placeholderTargets;
    const ids = placeholders.map((placeholder) => {
      const idSegments = placeholder.id.split('-');
      const id = Number(idSegments[idSegments.length - 1]);
      if (!!placeholder.offsetParent) {
        return id;
      }
      return null;
    });
    console.log(placeholders);

    const validIds = ids.filter(
      (id) => typeof id === 'number' && !Number.isNaN(id)
    );

    console.log(validIds);

    return { placeholders, validIds };
  }

  toggleAnchorAds = () => {
    const body = this.element;

    if (
      body.className.includes('pages_index') ||
      body.className.includes('properties_index')
    ) {
      window.ezstandalone.setEzoicAnchorAd(true);
      console.log("Ezoic setEzoicAnchorAd", true);

      console.log('anchor ads on');
    } else {
      window.ezstandalone.setEzoicAnchorAd(false);
      console.log("Ezoic setEzoicAnchorAd", false);

      console.log('anchor ads off');
    }
  };

  shouldUserSeeAds = () =>
    (doesHostAllowsSplitTesting() &&
      window.ezstandalone.isEzoicUser(percentageToRunEzoic) === true) ||
    !doesHostAllowsSplitTesting();

  initializeAndShowAds = (validIds) => {
    console.log('ads visible');
    ezstandalone.enableConsent();

    window.ezstandalone.define(validIds);
    console.log("Ezoic define", validIds);
    if (window.ezstandalone.enabled) {
      console.log("Ezoic enabled");
      window.ezstandalone.refresh();
      console.log("Ezoic refresh");
    } else {
      window.ezstandalone.enable();
      console.log("Ezoic enable");

      window.ezstandalone.display();
      console.log("Ezoic display");
    }
  };

  hidePlaceholders = (placeholders) => {
    console.log('ads not visible');
    placeholders.forEach((placeholder) => {
      placeholder.parentElement?.classList.add('!hidden');
    });
  };
}
