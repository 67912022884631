import { Spinner } from '~/atoms';
import { useDynamicImg } from '~/hooks';
import type { IconName } from '~/CampaignTable/App/types';

type Props = {
  iconName: IconName;
};

export const DynamicIcon = ({ iconName }: Props) => {
  const { isLoading, imagePath } = useDynamicImg(iconName, 'png');

  return (
    <div className='flex justify-center items-center w-[32px] h-[32px]'>
      {isLoading ? (
        <Spinner color='black' />
      ) : (
        <img src={imagePath} alt={iconName} className='w-full h-full' />
      )}
    </div>
  );
};
