type Props = {
  defaultUrl: string;
};

export const CheckoutRedirect = ({defaultUrl}: Props) => {
  if(window.top == window.self) {
    window.location.replace(window.localStorage.getItem('rebilly-redirect-back') || defaultUrl);
  } else {
    window.parent.postMessage('3DS-authentication-complete', '*');
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
