/* eslint-disable react/jsx-props-no-spreading */

import styles from './Button.module.scss';

type Props = React.ComponentProps<'button'> & {
  text: string;
};

export const Button = ({ text, ...rest }: Props) => (
  <button type='button' {...rest} className={styles.button}>
    {text}
  </button>
);
