import { Button } from '~/atoms';

type Props = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
  className?: string;
};

export const LocalButton = ({
  children,
  onClick,
  isLoading = false,
  className = '',
}: Props) => (
  <Button
    onClick={onClick}
    className={`text-base rounded font-bold px-6 py-3 ${
      isLoading ? 'cursor-not-allowed' : ''
    } ${className}`}
    disabled={isLoading}
  >
    {children}
  </Button>
);
