import {
  withFramePayGooglePayComponent,
  FramePayGooglePayProps,
} from '@rebilly/framepay-react';

type Props = FramePayGooglePayProps;

const FramePayGooglePayElement = ({ GooglePayElement }: Props) => (
  <GooglePayElement />
);

export const FramePayGooglePay = withFramePayGooglePayComponent(
  FramePayGooglePayElement
);
