import type { SearchResults } from '~/SearchBox/Api/useGetSearchResults';
import { isDataEmpty } from '~/SearchBox/Api/useGetSearchResults';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { List } from './List';
import styles from './Dropdown.module.scss';

type Props = {
  isRequestError: boolean;
  data: SearchResults | undefined;
  searchText: string;
  searchBaseUrl: string;
};

export const Dropdown = ({ isRequestError, data, searchText, searchBaseUrl }: Props) => {
  const { i18n } = useTranslationContext();

  const renderContent = () => {
    if (isRequestError) {
      return (
        <span className='text-red-1'>
          {i18n.t('javascript.search_box.query_error')}
        </span>
      );
    }

    if (data) {
      if (isDataEmpty(data))
        return (
          <div className='flex flex-col space-y-4'>
            <span className='text-base'>
              {i18n.t('javascript.search_box.no_results.info')}:{' '}
              <strong>{searchText}</strong>
            </span>
            <span className='text-base font-bold'>
              {i18n.t('javascript.search_box.no_results.suggestions')}:
            </span>
            <ul className='list-disc pl-5 space-y-2'>
              <li>
                {i18n.t('javascript.search_box.no_results.first_suggestion')}
              </li>
              <li>
                {i18n.t('javascript.search_box.no_results.second_suggestion')}
              </li>
            </ul>
          </div>
        );

      return <List data={data} searchText={searchText} searchBaseUrl={searchBaseUrl} />;
    }

    return 'Logic error! Should not reach that point';
  };

  return (
    <div
      className={`${styles.dropdown} absolute left-0 right-0 max-h-[40vh] bg-white rounded overflow-y-auto z-10 !mx-0 p-4`}
    >
      {renderContent()}
    </div>
  );
};
