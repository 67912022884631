import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['trigger', 'hiddenElement'];

  toggle(e) {
    const hiddenElements = this.hiddenElementTargets;
    const triggers = this.triggerTargets;
    const trigger = e.currentTarget;

    // clicked trigger has visible element (text or sth else what should appear after clicking trigger)
    if (trigger.getAttribute('style')) {
      trigger.removeAttribute('style');
      hiddenElements.forEach((element) => element.removeAttribute('style'));
      return;
    }

    // clicked trigger doesn't have visible element (all elements are hidden or element attached to other trigger is visible)
    triggers.forEach((trigger) => trigger.removeAttribute('style'));
    trigger.style.color = 'black';

    hiddenElements.forEach((element) => {
      element.removeAttribute('style');

      if (element.dataset.accordion === trigger.dataset.accordion) {
        const elementHeight = element.scrollHeight;

        element.style.opacity = 100;
        element.style.height = elementHeight + 'px';
        element.style.cursor = 'auto';
        element.style.pointerEvents = 'auto';
      }
    });
  }
}
