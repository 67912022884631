import { useTranslationContext } from '~/contexts/TranslationContext';
import type { SearchResults } from '~/SearchBox/Api/useGetSearchResults';
import { SingleSearchResult } from './SingleSearchResult';

type Props = {
  data: SearchResults;
  searchText: string;
  searchBaseUrl: string;
};

export const List = ({ data, searchText, searchBaseUrl }: Props) => {
  const { i18n } = useTranslationContext();

  return (
    <div className='flex flex-col divide-y divide-grey-9'>
      {data.cities.suggestions.length > 0 && (
        <SingleSearchResult
          title={i18n.t('javascript.search_box.dropdown_titles.city')}
          suggestions={data.cities.suggestions}
          searchText={searchText}
          searchBaseUrl={searchBaseUrl}
        />
      )}
      {data.postal_codes.suggestions.length > 0 && (
        <SingleSearchResult
          title={i18n.t('javascript.search_box.dropdown_titles.postal_code')}
          suggestions={data.postal_codes.suggestions}
          searchText={searchText}
          searchBaseUrl={searchBaseUrl}
        />
      )}
      {data.addresses.suggestions.length > 0 && (
        <SingleSearchResult
          title={i18n.t('javascript.search_box.dropdown_titles.address')}
          suggestions={data.addresses.suggestions}
          searchText={searchText}
          searchBaseUrl={searchBaseUrl}
        />
      )}
      {data.countries.suggestions.length > 0 && (
        <SingleSearchResult
          title={i18n.t('javascript.search_box.dropdown_titles.country')}
          suggestions={data.countries.suggestions}
          searchText={searchText}
          searchBaseUrl={searchBaseUrl}
        />
      )}
      {data.regions.suggestions.length > 0 && (
        <SingleSearchResult
          title={i18n.t('javascript.search_box.dropdown_titles.region')}
          suggestions={data.regions.suggestions}
          searchText={searchText}
          searchBaseUrl={searchBaseUrl}
        />
      )}
      {data.districts.suggestions.length > 0 && (
        <SingleSearchResult
          title={i18n.t('javascript.search_box.dropdown_titles.district')}
          suggestions={data.districts.suggestions}
          searchText={searchText}
          searchBaseUrl={searchBaseUrl}
        />
      )}
      {data.neighborhoods.suggestions.length > 0 && (
        <SingleSearchResult
          title={i18n.t('javascript.search_box.dropdown_titles.neighborhood')}
          suggestions={data.neighborhoods.suggestions}
          searchText={searchText}
          searchBaseUrl={searchBaseUrl}
        />
      )}
    </div>
  );
};
