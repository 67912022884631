import type { SortCategory } from '../types';
import { SingleFilter } from './SingleFilter';

type Props = {
  sortCategoriesList: SortCategory[];
  sortCategory: SortCategory;
  setSortCategory: React.Dispatch<React.SetStateAction<SortCategory>>;
  currentCountry: string;
  isLightTheme: boolean;
  i18nPrefix: string;
};

export const MobileFilters = ({
  sortCategoriesList,
  sortCategory,
  setSortCategory,
  currentCountry,
  isLightTheme,
  i18nPrefix,
}: Props) => (
  <div className='flex items-center space-evenly py-6 overflow-x-auto'>
    {sortCategoriesList.map((sortCategoryElement) => (
      <SingleFilter
        key={sortCategoryElement}
        sortCategoryElement={sortCategoryElement}
        isActive={sortCategoryElement === sortCategory}
        setSortCategory={setSortCategory}
        currentCountry={currentCountry}
        i18nPrefix={i18nPrefix}
        isLightTheme={isLightTheme}/>
    ))}
  </div>
);
