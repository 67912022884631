import axios from 'axios';
import { useMutation } from 'react-query';

import type { OrderData } from './types';

const createOrder = async (): Promise<OrderData> => {
  // @TODO add external data validation
  const { data } = await axios.post(`/api/v1/rebilly/orders`);
  return data as OrderData;
};

export const useCreateOrder = () => useMutation(createOrder, { retry: 1 });
