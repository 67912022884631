import type { Suggestion } from '~/SearchBox/Api/useGetSearchResults';
import styles from './SingleSearchResult.module.scss';

type Props = {
  title: string;
  suggestions: Suggestion[];
  searchText: string;
  searchBaseUrl: string;
};

export const SingleSearchResult = ({
  title,
  suggestions,
  searchText,
  searchBaseUrl,
}: Props) => {
  const showSuggestionText = (suggestionText: string) => {
    if (suggestionText.toLowerCase().startsWith(searchText.toLowerCase())) {
      const textToBeHighlighted = suggestionText.slice(0, searchText.length);
      const restText = suggestionText.slice(searchText.length);
      return (
        <>
          <span className='text-[#333]'>{textToBeHighlighted}</span>
          <span>{restText}</span>
        </>
      );
    }
    return suggestionText;
  };

  return (
    <div className={`${styles.wrapper} space-y-3`}>
      <h4 className='text-xl font-semibold m-0'>{title}</h4>
      <ul>
        {suggestions.map((suggestion) => (
          <li key={suggestion.magicKey}>
            <a
              href={`${window.location.origin}${searchBaseUrl}?id=${suggestion.magicKey}`}
              className='block text-base text-grey-3 font-medium px-2 py-1 hover:bg-grey-9/20 focus:bg-grey-9/20 focus:no-underline'
            >
              {showSuggestionText(suggestion.text)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
