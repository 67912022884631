// allow to use translations in jquery
import { I18n } from 'i18n-js';
import translations from './translations.json';

window.i18n = new I18n(translations);

const htmlElement = document.querySelector('html');
const currentLocale = htmlElement?.lang || 'en';

window.i18n.locale = currentLocale;
window.i18n.enableFallback = true;
