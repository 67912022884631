import { useRef, useEffect } from 'react';

import { useTranslationContext } from '~/contexts/TranslationContext';
import { useCellWidthContext } from '~/CampaignTable/contexts';
import type { SortOrder, SortCategory } from '../types';
import { TableHeader } from '~/CampaignTable/components';
import { MobileTableHeader } from './Mobile';
import { DesktopTableHeaders } from './Desktop';

type Props = {
  sortOrder: SortOrder;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
  sortCategoriesList: SortCategory[];
  sortCategory: SortCategory;
  setSortCategory: React.Dispatch<React.SetStateAction<SortCategory>>;
  isMobile: boolean;
  isLightTheme: boolean;
  currentCountry: string;
  i18nPrefix: string;
};

export const TableHeaders = ({
  sortOrder,
  setSortOrder,
  sortCategoriesList,
  sortCategory,
  setSortCategory,
  isLightTheme,
  isMobile,
  currentCountry,
  i18nPrefix,
}: Props) => {
  const { getCellWidth, cellWidth } = useCellWidthContext();

  const { i18n } = useTranslationContext();

  const tableHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tableHeaderRef.current) {
      const headerElements = [...tableHeaderRef.current.children];
      getCellWidth(100 / headerElements.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <div className={`flex sticky top-nav py-6${
        isLightTheme ? ' bg-[#FEFAF5]' : ' bg-white'
    }`} ref={tableHeaderRef}>
      <TableHeader width={cellWidth}>
        {i18n.t(`${i18nPrefix}.rank`)}
      </TableHeader>
      <TableHeader width={isMobile ? cellWidth : 2 * cellWidth}>
        {i18n.t(`${i18nPrefix}.region`)}
      </TableHeader>
      {isMobile ? (
        <MobileTableHeader
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          sortCategory={sortCategory}
          currentCountry={currentCountry}
          i18nPrefix={i18nPrefix}
        />
      ) : (
        <DesktopTableHeaders
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          sortCategoriesList={sortCategoriesList}
          sortCategory={sortCategory}
          setSortCategory={setSortCategory}
          currentCountry={currentCountry}
          i18nPrefix={i18nPrefix}
          isLightTheme={isLightTheme}/>
      )}
    </div>
  );
};
