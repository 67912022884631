import { Spinner } from '~/atoms';
import inputWrapperStyles from '../FramePaySepaElements.module.scss';
import styles from './IframeLoadingSpinner.module.scss';

type Props = {
  wrapperClassName: string;
};

export const IframeLoadingSpinner = ({ wrapperClassName }: Props) => (
  <div className={`${inputWrapperStyles.inputWrapper} ${wrapperClassName}`}>
    <div className={styles.spinnerWrapper}>
      <Spinner color='grey' />
    </div>
  </div>
);
