import { RefObject, useState, useMemo } from 'react';

import { useFinalizeOrder } from '~/SubscriptionCheckout/Api';
import type { PaymentToken } from '~/SubscriptionCheckout/Api/types';
import type {
  AttributionData,
  TrackingData,
} from '~/SubscriptionCheckout/App/types';
import {
  useFlowAfterSuccessfulPayment,
  useFlowAfter3dsComplete,
  usePaymentFlow,
} from '~/SubscriptionCheckout/hooks';
import { useTranslationContext } from '~/contexts/TranslationContext';
import { determineError } from '~/SubscriptionCheckout/utils/determineError';
import { ModalDimensions } from '~/SubscriptionCheckout/hooks/useModalDimensions/types';

type Args = {
  formRef: RefObject<HTMLFormElement>;
  Rebilly: any;
  attribution: AttributionData;
  trackingData: TrackingData | null;
  modalDimensions: ModalDimensions;
};

export const useCard = ({
  formRef,
  Rebilly,
  attribution,
  trackingData,
  modalDimensions,
}: Args) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cardDetailsComplete, setCardDetailsComplete] = useState({
    cardNumber: false,
    expiry: false,
    cvv: false,
  });
  const [isTokenRequestLoading, setIsTokenRequestLoading] = useState(false);

  const { i18n } = useTranslationContext();

  const {
    isIframeVisible,
    setIsIframeVisible,
    urlToOffsiteFlow,
    errorMsg,
    setErrorMsg,
    isPaymentSucceeded,
    setIsPaymentSucceeded,
    userDetailsModalMarkup,
    setUserDetailsModalMarkup,
    resetErrorMsg,
    renderErrorWhenTokenIsInvalid,
    paymentFlow,
    isPaymentLoading,
  } = usePaymentFlow();

  const finalizeOrder = useFinalizeOrder();

  const billingAddress = useMemo(
    () => ({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
    }),
    [firstName, lastName]
  );

  const leadSource = useMemo(
    () => ({
      medium: attribution.utmMedium,
      source: attribution.utmSource,
      campaign: attribution.utmCampaign,
      term: attribution.utmTerm,
      content: attribution.utmContent,
      referer: attribution.refererDomain,
    }),
    [attribution]
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetErrorMsg();

    if (!formRef.current) {
      return;
    }

    if (
      !cardDetailsComplete.cardNumber ||
      !cardDetailsComplete.expiry ||
      !cardDetailsComplete.cvv
    ) {
      setErrorMsg({
        msg: i18n.t('javascript.rebilly.subscription.error_invalid_card'),
        isHtmlInTranslation: false,
        alertClass: 'alert-danger',
      });
      return;
    }

    try {
      setIsTokenRequestLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const token: PaymentToken = await Rebilly.createToken(formRef.current, {
        billingAddress,
        leadSource,
      });
      setIsTokenRequestLoading(false);

      if (!token.id) {
        renderErrorWhenTokenIsInvalid(token);
        return;
      }

      paymentFlow(token.id, modalDimensions, trackingData);
    } catch (error) {
      console.error(error);
      const { msg, isHtmlUsed, alertClass } = determineError(error, i18n);
      setErrorMsg({ msg, isHtmlInTranslation: isHtmlUsed, alertClass });
      setIsTokenRequestLoading(false);
    }
  };

  useFlowAfter3dsComplete({
    setIsIframeVisible,
    setUserDetailsModalMarkup,
    setIsPaymentSucceeded,
    setErrorMsg,
    modalDimensions,
    trackingData,
  });

  useFlowAfterSuccessfulPayment(isPaymentSucceeded, userDetailsModalMarkup);

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setCardDetailsComplete,
    isLoading:
      isTokenRequestLoading || isPaymentLoading || finalizeOrder.isLoading,
    errorMsg,
    handleSubmit,
    isIframeVisible,
    urlToOffsiteFlow,
    isPaymentSucceeded,
  };
};
